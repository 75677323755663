import React from 'react';
import "./ContentWrapperBlock.scss"

const ContentWrapperBlock = ({ children, ContentMaxWidth, ContentPadding, scroll, verticalCenter, paddingTop}) => {

  ContentMaxWidth && document.documentElement.style.setProperty("--ContentWrapperBlock-maxWidth", ContentMaxWidth );
  ContentPadding && document.documentElement.style.setProperty("--ContentWrapperBlock-padding", ContentPadding );
  paddingTop && document.documentElement.style.setProperty("--ContentWrapperBlock-paddingTop", paddingTop );
  console.log("ContentMaxWidth: ", ContentMaxWidth)
  return (
    <div className={"ContentWrapperBlock-container " + (scroll && " ContentWrapperBlock-container_scroll ") + (verticalCenter && " ContentWrapperBlock-container_verticalCenter ") }>
      {children}
    </div>
  );
};

export default ContentWrapperBlock;