import React , {useState, useEffect} from "react";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import setPropertyVh from '../../utils/setPropertyVh';
import { useTranslation } from 'react-i18next';
import "./Discuss.scss"
import Titleblock from "components/PageBlocks/TitleBlock";
import FooterStickyBlock from "components/PageBlocks/FooterStickyBlock";
import PageWrapperBlock from "components/PageBlocks/PageWrapperBlock";
import ContentWrapperBlock from "components/PageBlocks/ContentWrapperBlock";



const Discuss = ({
    img, 
    // playRefAudio,
    // pauseRefAudio,
    // teamworkMusicRef,
    addEvent,
    onContinue,
    onBack, 
    CurrentSelectedStepItem,
    discussTitle,
    discussSubtitle,
    question
    // AnimalType,
    // instructorName,
    }) => {
    
          
    // useEffect(() => {
    //     playRefAudio(teamworkMusicRef, true);
    // }, []);
    
        
    setPropertyVh();
    const {t} = useTranslation();
    const [CHEATS, setCHEATS] = useState(0)
    // playRefAudio(teamworkMusicRef, true)

    function handleContinueTeamwork () {
        addEvent("continue", "stepItem", "Discuss", CurrentSelectedStepItem)
        onContinue()
    }

    function handleOnBack () {
        addEvent && addEvent("back", "stepItem", "Discuss", CurrentSelectedStepItem);
        onBack()
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
        event.preventDefault();
          switch (event.key) {
            case ' ':
            case 'Enter':
              handleContinueTeamwork();
              break;
            case 'Delete':
            case 'Backspace':
              handleOnBack();
              break;
            default:
              break;
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
      return (
          <>
          <PageWrapperBlock 
            backgroundColor = "var(--page-background-color)"
            ClassName={"discuss"}
          >
            <ContentWrapperBlock
                ContentMaxWidth={"900px"}
                ContentPadding={"0 20px"}
                // scroll
                // verticalCenter
                paddingTop = "50px"
                
            >
                <Titleblock 
                    title={t(discussTitle)}
                    subtitle={t(discussSubtitle)}
                    titleFontSize="50px"
                />
                <div className={"ContentWrapperBlock-flex-column"}>
                    <div className="ContentWrapperBlock-video-container">  
                        <img src={img}/>
                    </div> 
                    {/* <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3> */}
                </div>
                {/* <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3> <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3> <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3>
                <h3>Does this happen to you too? What do you do in this situation? What can help these two get along?</h3> */}
            </ContentWrapperBlock>
            <FooterStickyBlock 
                    button1={{ text: t("general.replay"), className: "catbearsCTA catbearsCTA-primary", onClick: handleOnBack, iconStart: "/assets/icons/icon_rewind.svg" }}
                    button2={{ text: t("general.continue"), className: "catbearsCTA catbearsCTA-primary", onClick: handleContinueTeamwork, iconEnd: "/assets/icons/icon_arrow-forward.svg" }}
                    backgroundColor = "var(--page-background-color)"
                    // backgroundBright
                />
          </PageWrapperBlock>
        </>
    )
}

export default Discuss
