
const LessonBackgroundSteps = [
    { // Skill
      buttonShape: "circle", // 
      size : "coinImg",
      name: "teamworkSkills.idea-behind-mistake.name", 
      description: "teamworkSkills.idea-behind-mistake.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      coinName: "idea-behind-mistake",
      // requirements: [],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_idea-behind-mistake.jpg",
      stepItems : [
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/idea-behind-mistake_1.mp4",
          isStepGoal: true
        },
        {
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/alexander-and-mistake.jpg",
          discussTitle: "teamworkSkills.idea-behind-mistake.discussTitle",
        },
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/idea-behind-mistake_2.mp4",
          isStepGoal: true
        }
      ]
    },  
    { // work-station
      StepNumberToShow: 1,
      name: "course.theatre.lesson-background.steps.work-station.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.work-station.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "activityPage"}, {name: "bristol"}, {name: "stick-glue"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_work-station.jpg",
      stepItems : [
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_work-station.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_work-station.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // color-the-background
      StepNumberToShow: 2,
      name: "course.theatre.lesson-background.steps.color-the-background.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.color-the-background.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "brush"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_color-the-background.jpg",
      stepItems : [
        { 
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/background_color-the-background.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_color-the-background.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // ground
      StepNumberToShow: 3,
      name: "course.theatre.lesson-background.steps.ground.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.ground.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "scissors"}, {name: "stick-glue"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_ground.jpg",
      stepItems : [
        { 
          pageState: "video",
          // url: "https://media.catbears.com/course_theatre/testvid.mp4",
          url: "https://media.catbears.com/course_theatre/background_ground.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_ground.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // hills
      StepNumberToShow: 4,
      name: "course.theatre.lesson-background.steps.hills.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.hills.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "scissors"}, {name: "stick-glue"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_hills.jpg",
      stepItems : [
        { 
          pageState: "video",
          // url: "https://media.catbears.com/course_theatre/testvid.mp4",
          url: "https://media.catbears.com/course_theatre/background_hills.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_hills.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // objects
      StepNumberToShow: 5,
      name: "course.theatre.lesson-background.steps.objects.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.objects.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "scissors"}, {name: "stick-glue"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_objects.jpg",
      stepItems : [
        { 
          pageState: "video",
          // url: "https://media.catbears.com/course_theatre/testvid.mp4",
          url: "https://media.catbears.com/course_theatre/background_objects.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_objects.mp4",
          string: "teamwork text here",
        }
      ]
    },
    { // color
      StepNumberToShow: 6,
      name: "course.theatre.lesson-background.steps.color.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-background.steps.color.description",
      desciptionTips: ["Tip 1", "Tip 2"],
      type: "videoTeamwork",
      requirements: [{name: "allColors"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/background_color.jpg",
      stepItems : [
        { 
          pageState: "video",
          // url: "https://media.catbears.com/course_theatre/testvid.mp4",
          url: "https://media.catbears.com/course_theatre/background_color.mp4",
          isStepGoal: true
        },
        {
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/background_color.mp4",
          string: "teamwork text here",
        }
      ]
    }   
    ]
    
    export default LessonBackgroundSteps;