const printFunc = async (urls) => {
  if (!Array.isArray(urls)) {
    urls = [urls];
  }

  urls.forEach(url => {
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>The Catbears - Print The Activity Pages</title>
            <style>
              body, html, iframe {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
              }
            </style>
          </head>
          <body>
            <iframe src="${url}" style="border: none; width: 100%; height: 100%;" onload="this.contentWindow.document.body.style.zoom = '100%'; this.contentWindow.print(); this.onload = null;"></iframe>
          </body>
        </html>
      `);
      printWindow.document.close();
    } else {
      console.error('Failed to open print window');
    }
  });
};

export default printFunc;
