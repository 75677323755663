import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';
import React, {useState, useEffect, useRef} from 'react';
import {graphql, useQuery} from 'react-apollo';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import {addMirror} from '../../graphql/mutations';
import {getRelationship} from '../../graphql/queries';
import './Summary.scss';
import html2canvas from 'html2canvas'
import ConflictSummary from "../../components/ConflictSummary/ConflictSummary";


function Summary(props) {

  function showCopyNotification() {

    setTimeout(() => {
      setShowNotificationState(true)
      setTimeout(() => {
        setShowNotificationState(false)

      }, 2800);
    }, 1000);

  }

  const [showNotificationState, setShowNotificationState] = useState(false);

  /// DIALOG -------
  const toggleEditDialog = (open, setIsEditDialogOpen) => (event) => {
    setIsEditDialogOpen({open});
  };
  const historyHook = useHistory();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  // DIALOG END --------
  const {t} = useTranslation();
  const {match} = props;
  const {params} = match;
  const {id: relationshipID, journeyID} = params;
  const summaryPageRef = useRef();
  const html2canvasRef = useRef();
  const summaryFeltImgRef = useRef();
  const summaryFeltImgTempRef = useRef();

  const html2img = () => {
    if (html2imgReady) {
      html2canvas(html2canvasRef.current).then(canvas => {
        const dataUrl = canvas.toDataURL();
        const imageFoo = document.createElement('img');
        imageFoo.src = dataUrl;
        (summaryFeltImgRef.current).appendChild(imageFoo);
        (html2canvasRef.current).style.display = "none";
        (summaryFeltImgTempRef.current).style.display = "none";
        (summaryFeltImgRef.current).style.display = "inline-block";
        console.log("html2img")
        html2imgReady = false;
      })
    }
  }

  const {loading, error, data} = useQuery(gql(getRelationship), {
    variables: {id: relationshipID},
    fetchPolicy: 'network-only'
  });

  let html2imgReady = true;
  useEffect(() => {
    console.log("useEffect")
    var timeout = setTimeout(() => {
      html2img();
    }, 1000)
  }, [])

  if (loading) return <Loading/>;
  const {
    mySideMirrors,
    name,
    partnerName,
    animalId,
    partnerAnimalId,
    relation,
    gender,
    partnerGender
  } = data.getRelationship;
  const mySideMirror = mySideMirrors.find((m) => m.id === journeyID);
  const quests = mySideMirror.progress.flowState;
  const {createdAt} = mySideMirror;
  const dateCreated = new Date(createdAt).toLocaleDateString('en-GB')

  const copyDivToClipboard = () => {
    var range = document.createRange();
    range.selectNode(summaryPageRef.current);
    // range.summaryPageRef;
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges();// to deselect
    showCopyNotification();
  }


  return (
    <>
      <div className="summaryCopyNotification-container ">
        <div
          className={"summaryCopyNotification " + (showNotificationState ? "" : " summaryCopyNotificationHidden")}>{t('general.CopyToClipboard')}</div>
      </div>

      <div className="summary summary-padding">
        <SideBarPanelNav
          left1={'backButton'}
          right={'copytoClipboard'}
          copytoClipboardFunc={copyDivToClipboard}
          rightAction={() => setIsEditDialogOpen(true)}
          center={'twoLinesTopSubTitle'}
          centerSubTitle={t(`homepage.meAndPartner`, {
            name: name,
            partnerName: partnerName
          })}
          centerTitle={t('summary.summaryOf') + (mySideMirror.journeyProps.situationName || t(`mirroring.defaultJourneyName`))}
          // right={'settingsButton'}
          leftTo={`/relationship/${relationshipID}/journey/${journeyID}`}
        />

        <ConflictSummary html2canvasRef={html2canvasRef}
                         relationship={data.getRelationship}
                         summaryFeltImgTempRef={summaryFeltImgTempRef}
                         copyDivToClipboard={copyDivToClipboard}
                         summaryFeltImgRef={summaryFeltImgRef}
                         mirrorID={journeyID}
                         summaryPageRef={summaryPageRef}/>
      </div>
    </>
  );
}

export default compose(
  graphql(gql(addMirror), {
    options: (props) => ({
      refetchQueries: [{query: gql(getRelationship)}],
      awaitRefetchQueries: false
    })
  })
)(Summary);



