import React from 'react';
import { useTranslation } from "react-i18next";
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import "./LogoCatbears.scss"

function LogoCatbears({isSoundSwitch}) {

  const {i18n } = useTranslation();

  return (
    <>
    <div className="LogoCatbears-container noselect ">
      <div className="toggle-switch hovering">
        {isSoundSwitch && <ToggleSwitch /> }
        
      </div>
        <img className="catbears-logo hovering" src={(i18n.translator.language == `en` ? "/assets/icons/logo-catbears__en.png" : "/assets/icons/logo-catbears__he.png")}/>
    </div>
    </>
  );
}

export default LogoCatbears;
