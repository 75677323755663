import React, {useState, useEffect} from 'react';
import "./Loading.scss";
import { useTranslation } from "react-i18next";


function Loading ({backgroundTransparent = false}) {

    const { t, i18n } = useTranslation();

    if(backgroundTransparent){
        document.documentElement.style.setProperty("--loadingBackground", "rgba(255, 255, 255, 0)");
    }
    // const [classesState, setClassesState] = useState("displayNone")
    const [classesState, setClassesState] = useState("")
    useEffect(() => { 
    //    const L =  setTimeout(function(){setClassesState("fade-in")}, 400);  
       const L =  setTimeout(function(){setClassesState("")}, 400);  
       return clearTimeout(L)
    },[]
    )
    
    return (
        <>
            <div className={"Loading texture noselect " }>
                <div className="Loading--container ">
                    <div>
                        <img className={"Loading__img--preloader hoverAnimation" + classesState} src="/catbears-logo_loading.gif"/>
                    </div>
                    <p>{t(`general.loading`)}</p>
                </div>
            </div>
        </>
    )
}

export default Loading;