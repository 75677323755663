import React from 'react';
import Animal from '../../components/Animal/Animal';
import './Instructor.scss'
const Instructor = ({ 
    AnimalType, 
    instructorName,
    intructorBottom,
    intructorSize,
    intructorNameSize,
    intructorNameColor,
    intructorNameMargin

  }) => {

    instructorName && document.documentElement.style.setProperty("--instructor-name", instructorName );
    intructorBottom && document.documentElement.style.setProperty("--intructor-bottom", intructorBottom );
    intructorSize && document.documentElement.style.setProperty("--instructor-size", intructorSize );
    intructorNameSize && document.documentElement.style.setProperty("--instructor-nameSize", intructorNameSize );
    intructorNameColor && document.documentElement.style.setProperty("--instructor-nameColor", intructorNameColor );
    intructorNameMargin && document.documentElement.style.setProperty("--instructor-nameMargin", intructorNameMargin );

    return (
      <div className="Lesson_Instructor--component">
        <div className="Lesson_Instructor--animal">
          <Animal id={AnimalType} alt="Catbears Instructor" />
        </div>
        <div className="Lesson_Instructor--name">{instructorName}</div>
      </div>
    );
};

export default Instructor;