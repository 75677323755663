const getProgressAsArr = (userProgress) => {
    if (!userProgress || !userProgress.courses) {
      return [0, 0, 0, 0];
    }
  
    let highestCourseIndex = 0;
    let highestLessonIndex = 0;
    let highestStepIndex = 0;
    let highestStepItemIndex = 0;
  
    userProgress.courses.forEach((course, courseIndex) => {
      if (course.completed) {
        highestCourseIndex = courseIndex + 1;
      } else {
        course.lessons.forEach((lesson, lessonIndex) => {
          if (lesson.completed) {
            highestLessonIndex = lessonIndex + 1;
          } else {
            lesson.steps.forEach((step, stepIndex) => {
              if (step.completed) {
                highestStepIndex = stepIndex + 1;
              } else {
                step.stepItems.forEach((stepItem, stepItemIndex) => {
                  if (stepItem.completed) {
                    highestStepItemIndex = stepItemIndex + 1;
                  }
                });
              }
            });
          }
        });
      }
    });
  
    return [highestCourseIndex, highestLessonIndex, highestStepIndex, highestStepItemIndex];
  };

  function generateProgressTree(courseRecipe) {
    return {
      courses: [{
        name: courseRecipe.name,
        completed: false,
        lessons: courseRecipe.lessons.map(lesson => ({
          completed: false,
          steps: Array.isArray(lesson.steps)
            ? lesson.steps.map(step => ({
                completed: false,
                stepItems: Array.isArray(step.stepItems)
                  ? step.stepItems.map(() => ({ completed: false }))
                  : [],
              }))
            : [],
        })),
      }],
    };
  }

  const generateProgressTreeFromArray = (courseRecipe, progressArr) => {
    const [Cprog, Lprog, Sprog, SIprog] = progressArr;
  
    const updatedTree = {
      courses: [{
        name: courseRecipe.name,
        completed: Cprog > 0,
        lessons: courseRecipe.lessons.map((lesson, lIndex) => {
          let lessonCompleted = false;
          if (lIndex < Lprog) {
            lessonCompleted = true;
          } else if (lIndex === Lprog) {
            lessonCompleted = false;
          }
          return {
            completed: lessonCompleted,
            steps: Array.isArray(lesson.steps) ? lesson.steps.map((step, sIndex) => {
              let stepCompleted = false;
              if (lIndex < Lprog || (lIndex === Lprog && sIndex < Sprog)) {
                stepCompleted = true;
              } else if (lIndex === Lprog && sIndex === Sprog) {
                stepCompleted = false;
              }
              return {
                completed: stepCompleted,
                stepItems: Array.isArray(step.stepItems) ? step.stepItems.map((_, siIndex) => {
                  let stepItemCompleted = false;
                  if (lIndex < Lprog || (lIndex === Lprog && sIndex < Sprog) || (lIndex === Lprog && sIndex === Sprog && siIndex <= SIprog)) {
                    stepItemCompleted = true;
                  }
                  return {
                    completed: stepItemCompleted,
                  };
                }) : [],
              };
            }) : [],
          };
        }),
      }],
    };
  
    return updatedTree;
  };

  const getAllTreeItems = (userProgress) => {
    const AllTreeItems = [];
  
    userProgress.courses.forEach((course, courseIndex) => {
      AllTreeItems.push({ type: "course", completed: course.completed, itemIndex: courseIndex });
      
      course.lessons.forEach((lesson, lessonIndex) => {
        AllTreeItems.push({ type: "lesson", completed: lesson.completed, itemIndex: lessonIndex });
  
        lesson.steps.forEach((step, stepIndex) => {
          AllTreeItems.push({ type: "step", completed: step.completed, itemIndex: stepIndex });
          
          step.stepItems.forEach((stepItem, stepItemIndex) => {
            AllTreeItems.push({ type: "stepItem", completed: stepItem.completed, itemIndex: stepItemIndex });
          });
        });
      });
    });
  
    return AllTreeItems;
  };

  const checkForProgressInLesson = (lesson) => {
    console.log("lesson= ", lesson)
    return lesson.completed || lesson.steps.some(step => 
      step.completed || step.stepItems.some(stepItem => stepItem.completed)
    );
  };
  
  
  

  export { 
    getProgressAsArr, 
    generateProgressTree, 
    generateProgressTreeFromArray,
    getAllTreeItems,
    checkForProgressInLesson
  };
