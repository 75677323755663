
const LessonHouseSteps = [
  { // Skill
    buttonShape: "circle", // 
    size : "coinImg",
    name: "teamworkSkills.stop-to-relax.name", 
    description: "teamworkSkills.stop-to-relax.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    coinName: "stop-to-relax",
    // requirements: [],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/how-to-stop-the-anger.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/stop-to-relax.mp4",
        isStepGoal: true
      }
    ]
  },
  { // pink-tree 
    name: "conflict-simulator.pink-tree.name",
    size : "XL", 
    state: "selected", 
    description: "conflict-simulator.pink-tree.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "conflict-simulator",
    // AnimalName : "bear",
    ImageToShow: "/assets/course/theatre/videos-and-thumbnails/pink-tree_image-to-show.png",
    // requirements: [],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb-pink-tree.jpg",
    stepItems : [
      {
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/pink-tree_1.mp4",
        isStepGoal: true
      },
      {
        pageState: "discuss",
        img: "/assets/course/theatre/videos-and-thumbnails/pink-tree_discuss.jpg",
        discussTitle: "conflict-simulator.pink-tree.discussTitle",
        discussSubtitle: "conflict-simulator.pink-tree.discussSubtitle",
      },
      {
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/pink-tree_2.mp4",
        isStepGoal: true
      },
    ]
  },

  { // glue on cardboard
    StepNumberToShow: 1,
    name: "course.theatre.lesson-house.steps.glue-on-cardboard.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.glue-on-cardboard.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "activityPage"}, {name: "cardboard"}, {name: "stick-glue"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-01_glue-on-cardboard.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-01_glue-on-cardboard.mp4",
        string: "teamwork text here",
      }
    ]
  },
  { // cut-the-house
    StepNumberToShow: 2,
    name: "course.theatre.lesson-house.steps.cut-the-house.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.cut-the-house.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "scissors"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-02_cut-the-house.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-02_cut-the-house.mp4",
        string: "teamwork text here",
      }
    ]
  },
  { // fold-cardboard
    StepNumberToShow: 3,
    name: "course.theatre.lesson-house.steps.fold-cardboard.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.fold-cardboard.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "scissors"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-03_fold-cardboard.jpg",
    stepItems : [
      { 
        pageState: "video",
        // url: "https://media.catbears.com/course_theatre/testvid.mp4",
        url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-03_fold-cardboard.mp4",
        string: "teamwork text here",
      }
    ]
  },
  { // base
    StepNumberToShow: 4,
    name: "course.theatre.lesson-house.steps.base.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.base.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "cardboard"}, {name: "scissors"}, {name: "bottle-glue"}, {name: "tape"}],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-04_base.jpg",
    stepItems : [
      { 
        pageState: "video",
        url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-04_base.mp4",
        string: "teamwork text here",
      }
    ]
  },
  { // windows-and-doors 
    StepNumberToShow: 5,
    name: "course.theatre.lesson-house.steps.windows-and-doors.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.windows-and-doors.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "activityPage"}, {name: "scissors"}, {name: "stick-glue"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-05_windows-and-doors.jpg",
    stepItems : [
      { 
        pageState: "video",
        // url: "https://media.catbears.com/course_theatre/testvid.mp4",
        url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-05_windows-and-doors.mp4",
        string: "teamwork text here",
      }
    ]
  },
  { // color 
    StepNumberToShow: 6,
    name: "course.theatre.lesson-house.steps.color.name",
    size : "S", 
    state: "disabled", 
    description: "course.theatre.lesson-house.steps.color.description",
    desciptionTips: ["Tip 1", "Tip 2"],
    type: "videoTeamwork",
    requirements: [{name: "allColors"} ],
    videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/house_step-06_color.jpg",
    stepItems : [
      { 
        pageState: "video",
        // url: "https://media.catbears.com/course_theatre/testvid.mp4",
        url: "https://media.catbears.com/course_theatre/house_step-06_color.mp4",
        isStepGoal: true
      },
      {
        pageState: "teamWork",
        url: "https://media.catbears.com/course_theatre/house_step-06_color.mp4",
        string: "teamwork text here",
      }
    ]
  },     
  ]
  
  export default LessonHouseSteps;