import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './VideoStep.scss';

function VideoStep({thumbnailURL , videoURL, title, subtitle, description, link, linkTxt }) {

  const handleFullScreen = () => {
    const player = document.getElementById('video-player');
    if (player) {
      player.requestFullscreen();
    }
  };

  return (
    <div className="stepItem_VideoStep-container noselect">
      {/* <h1 className="stepItem_VideoStep-title form-label_center">{title}</h1> */}
      <div className={"stepItem_VideoStep-thumbnail"} >
        <ReactPlayer
          controls
          playing
          id="video-player"
          playIcon={<img src="/assets/icons/play-icon.png" style={{ height: '60px', width: '60px' }} />}
          light={thumbnailURL}
          url={videoURL}
        />
      </div>
      {/* <div>
        <button className="watch-btn noselect" onClick={handleFullScreen}>Watch</button>
      </div> */}

      {/* <h2 className={subtitle ? '' : 'displayNone'}>{subtitle}</h2> */}
      {/* <p className={'stepItem_VideoStep-description'}>{description}</p> */}
      {/* {link ? <a className="link_button" href={link}>{linkTxt}</a> : ''} */}
    </div>
  );
}

export default VideoStep;


// import './VideoStep.scss';
// url= "/assets/conflict-videos/croc_koala_part1.mp4"

