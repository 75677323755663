import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useMutation} from 'react-apollo';
import gql from 'graphql-tag';
import TopNavbar from 'components/TopNavbar/TopNavbar';
import Loading from '../../components/Loading/Loading';
import LessonListItem from 'components/LessonListItem/LessonListItem';
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import SubscriptionModal from '../../components/SubscriptionModal/SubscriptionModal';
import UserContext from "../../pages/Login/UserContext";
import {updateUserProgress} from '../../graphql/mutations';
import CourseTheatreRecipe from "../../flows/CourseTheatreRecipe";
import { GlobalContext } from "context/GlobalContext";
import './Lessons.scss';
import {
  getProgressAsArr, 
} from "utils/courseFunctions"

function Lessons({user, authContext}) {
  
  const [CHEATS, setCHEATS] = useState(0)
  const [CHEATSprogress, setCHEATSprogress] = useState(user.userProgress)
  
  const userID = user.id;
  const {t} = useTranslation();

  const thisCourseNumber = 0;
  const LessonsRecipe = CourseTheatreRecipe.lessons;

  const [UserProgress, setUserProgress] = useState(); 

  const {VolumeSwitchState, setVolumeSwitchState} = useContext(GlobalContext);

  useEffect(() => {
    if (user) {
      const initialProgress = user.userProgress || {};
      setUserProgress(getProgressAsArr(initialProgress));
      // console.log('UserProgress:', initialProgress);

      [coursesProgress, lessonsProgress, stepsProgress, stepItemsProgress] = (UserProgress || [0,0,0,0])
    }
  }, [user, setUserProgress]);

  // console.log("UserProgress ", UserProgress)

let [coursesProgress, lessonsProgress, stepsProgress, stepItemsProgress] = (UserProgress || [0,0,0,0])
  // Array.isArray(user.userProgress) && user.userProgress.length > 0
  //     ? user.userProgress
  //     : {};
const [ShouldCelebrateLessonCompleted, setShouldCelebrateLessonCompleted] = useState(false);

  const getLessonState = (lessonIndex) => {
    //check if entire course is completed
    if(coursesProgress <= thisCourseNumber){
      //check lesson state
      if (lessonsProgress == lessonIndex) {
        return 'current';
      } else if (lessonsProgress > lessonIndex) {
        return 'completed';
      } else {
        return 'disabled';
      }
    } else {
      return 'completed';
    }    
};

//

//
const pageBackgroundColor = (CourseTheatreRecipe.lessons[lessonsProgress].backgroundColor ? CourseTheatreRecipe.lessons[lessonsProgress].backgroundColor : "red");
document.documentElement.style.setProperty("--page-background-color",pageBackgroundColor);

const { handleUserUpdate } = useContext(UserContext);

const [updateUser, { data, loading, error }] = useMutation(gql(updateUserProgress), {
  update(cache, { data: { setUserProgress } }) {
    if (!setUserProgress) {
      console.error('setUserProgress is undefined or null');
      return;
    }
    handleUserUpdate(setUserProgress);
  },
});

const UpdateUserProgressFunc = async (someProgress) => {
  try {
    await updateUser({ variables: { userProgress: someProgress } });
    console.log('User progress updated successfully.');
  } catch (error) {
    console.error('Error updating user progress:', error);
  }
};

const exampleProgress = {
  courses: [
    {
      name: "Theatre",
      completed: false,
      lessons: [
        {
          completed: true,
          steps: [
            {
              completed: true,
              stepItems: [
                { completed: true },
                { completed: false },
              ],
            },
            {
              completed: false,
              stepItems: [],
            },
          ],
        },
        {
          completed: false,
          steps: [
            {
              completed: false,
              stepItems: [],
            },
            {
              completed: false,
              stepItems: [],
            },
          ],
        },
        {
          completed: false,
          steps: [],
        },
        {
          completed: false,
          steps: [],
        },
      ],
    },
  ],
};

// const delay = (ms) => {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// };

  
//   const [isHeLangState, setIsHeLangState] = useState(false);
  
  return (
    <>
      {CHEATS ? 
        <>
        <div>
        </div>
        </>
      : ""
      }

      <TopNavbar
        showNavBG={true}
        showLogo={false}
        authContext={authContext}
        user={user}
        title={t(`course.theatre.courseName`)}
        BackLinkTo={'/'}
        backgroundColor={pageBackgroundColor}
        border={true}
        opacity={'0.93'}
        texture={true}
        VolumeSwitchState = {VolumeSwitchState} 
        setVolumeSwitchState = {setVolumeSwitchState}
      ></TopNavbar>
      <div className="course--container  course--container__theatre noselect texture">
        <div className="Lessons__list ">
          {LessonsRecipe.map((lessonRecipe, index) => {
            return (
            <LessonListItem
              key = {index}
              pathname={lessonRecipe.pathname}
              progressStatus={"completed"}
              title={t(lessonRecipe.name)}
              description={t(lessonRecipe.description)}
              imgPath={lessonRecipe.imgPath}
              instructor={lessonRecipe.instructor}
              AnimalType={lessonRecipe.AnimalType}
              instructorName={t(lessonRecipe.instructorName)}
              state = {getLessonState(index)}
              isCurrent = {lessonsProgress == index}
              badge = {lessonRecipe.badge}
              size = {lessonRecipe.size}
            />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Lessons;
