// ---------- Relationship.jsx
// import AnimalsThumbnail from "../../components/AnimalsThumbnail/AnimalsThumbnail";
import Dialog from '@material-ui/core/Dialog';
import gql from 'graphql-tag';
import React, {useContext, useEffect, useState} from 'react';
import {useMutation, useQuery} from 'react-apollo';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import AnimalsThumbnail from '../../components/AnimalsThumbnail/AnimalsThumbnail';
import Button from '../../components/Button/Button';
import JourneyListItem from '../../components/JourneyListItem/JourneyListItem';
import JourneyListNewButton from '../../components/JourneyListNewButton/JourneyListNewButton';
import Loading from '../../components/Loading/Loading';
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import {
  deleteRelationship as deleteRelationshipMutation,
  addMirror as addMirrorMutation
} from '../../graphql/mutations';
import {getRelationship} from '../../graphql/queries';
import setPropertyVh from '../../utils/setPropertyVh';
import JourneyFlow from '../Journey/JourneyFlow.json';
import SubscriptionModal from "../../components/SubscriptionModal/SubscriptionModal";
import UserContext from "../Login/UserContext";
import './Journeys.scss';

setPropertyVh();

const toggleDialog = (open, setCardEditorState) => (event) => {
  setCardEditorState({isOpen: open});
};


// Entry Point ==>
function Journeys({match}) {

  const {params} = match;
  const {user} = useContext(UserContext);

  const [deleteRelationship] = useMutation(gql(deleteRelationshipMutation));
  const [addMirror] = useMutation(gql(addMirrorMutation));
  const historyHook = useHistory();
  const {t} = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [relationship, setRelationship] = useState({});
  const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const {loading, error, data} = useQuery(gql(getRelationship), {
    variables: {id: params.id || 0}
  });
  const [isHeLangState, setIsHeLangState] = useState(false);

  const calcJourneysProgress = () => {
    console.log("! JourneyFlow", JourneyFlow)

  }

  useEffect(() => {
    if (document.body.getAttribute(`lang`) === 'he') {
      setIsHeLangState(true);
    }
  }, [params]);

  if (error) {
    alert(error);
    historyHook.push('/');
  }
  if (loading) return <Loading/>;

  async function createNewMirror(relationshipId) {
    const res = await addMirror({variables: {input: {relationshipId}}});
    const {id: mirrorId} = res.data.addMirror;
    historyHook.push(`/relationship/${params.id}/journey/${mirrorId}`);
  }

  const relationshipQuery = data.getRelationship;

  console.log ("relationshipQuery", relationshipQuery)


  const {id: relationshipId} = relationshipQuery;
  return (
    <>
      <Dialog
        title={t('relationship.edit-relationship')}
        open={isEditDialogOpen}
        onClose={toggleDialog(false, setIsEditDialogOpen)}
      >
        <div className="modal-buttons">
          <Link
            to={{
              pathname: `/relationship/${params.id}/edit`,
              state: {relationship}
            }}
          >
            <Button
              className="relationship-page__settings-edit-button texture"
              isLink={false}
              text={t(`relationship.edit.edit-sides`)}
            />
          </Link>

          <div className="modal-buttons__delete">
            <Button
              classes="relationship-page__settings-delete-button "
              text={t(`relationship.edit.delete`)}
              onClick={async () => {
                // const confirmDelete = window.prompt("Are you sure you want to delete this relationship? This action cannot be undone. Type 'Delete' to confirm:");
                const confirmDelete = window.prompt(t("general.sure-delete-relationship") + `\n\n` + (t("general.type-delete-confirm")));
                if (confirmDelete === "Delete") {
                  await deleteRelationship({variables: {id: params.id}});
                  historyHook.push('/');
                }
              }}
            />
          </div>
          <p className="micro-text micro-text__white">{t(`relationship.edit.delete-warning`)}</p>
          <Button
            className="close"
            text={t(`general.close`)}
            onClick={() => setIsEditDialogOpen(false)}
            // onClick={toggleDialog(false, setIsEditDialogOpen)}
          />
        </div>
      </Dialog>
      <div className="CONTENT__container LAYOUT-SIMPLE noselect ">
        <SideBarPanelNav
          left1={'backButton'}
          rightAction={() => setIsEditDialogOpen(true)}
          center={'twoLinesTopSubTitle'}
          centerTitle={t(`mirroring.listOfMirrors`)}
          centerSubTitle={t(`relationship.title`, {
            name: relationshipQuery.name,
            partnerName: relationshipQuery.partnerName
          })}

          right={'settingsButton'}
        />
        <div className="CONTENT__list--container ">
          <AnimalsThumbnail
            thumbnailClassName={"Journeys--thumbnail"}
            animalId={relationshipQuery.animalId}
            partnerAnimalId={relationshipQuery.partnerAnimalId}
            name={relationshipQuery.name}
            partnerName={relationshipQuery.partnerName}
          />
          <div className="journeys__list">
            {relationshipQuery.mySideMirrors.map((mirror) => {
              return (
                <JourneyListItem
                  itemRelationshipID={relationshipQuery.id}
                  itemJourneyID={mirror.id}
                  key={mirror.id}
                  journeyType={mirror.journeyType || t(`mirroring.defaultJourneyType`)}
                  journeyName={t(`mirroring.defaultJourneyName`)}
                  currentJourneyItem={calcJourneysProgress()}
                  maxJourneyItem={JourneyFlow.journeyItems.length}
                  stepName={mirror.stepName || ''}
                  pathname={`/relationship/${relationshipQuery.id}/journey/${mirror.id}`}
                  state={{relationship: relationshipQuery}}
                  dateCreated={
                    isHeLangState
                      ? new Date(mirror.createdAt).toLocaleDateString('en-GB')
                      : new Date(mirror.createdAt).toLocaleDateString()
                  }

                  params={params}
                  match={match}
                />
              );
            })}
            <JourneyListNewButton onClick={user.isSubscribed ?
              () => createNewMirror(relationshipId) :
              // () => setSubscriptionModalOpen(true)}
              () => createNewMirror(relationshipId)} //REMOVING PAYWALL 7.10.2023
              key={'create-new-mirror'}
            />
          </div>
        </div>
      </div>

      <SubscriptionModal isOpen={isSubscriptionModalOpen} onExit={() => setSubscriptionModalOpen(false)}/>

    </>
  );
}

export default Journeys;
