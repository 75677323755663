import React from "react";
import "./StepRequirement.scss";
import { useTranslation } from 'react-i18next';

function StepRequirement({ type, showDescription}) {

    const {t} = useTranslation();

    let reqName = "";
    let reqImg = "";
    let description = "";
    
    switch (type) {
        case 'activityPage':
            reqName = t("requirements.activityPage");
            reqImg = "/assets/icons/reqImg_activity-page.png";
            break;
        case 'scissors':
            reqName = t("requirements.scissors");
            reqImg = "/assets/icons/reqImg_scissors.png";
            break;
        case 'brush':
            reqName = t("requirements.brush");
            reqImg = "/assets/icons/reqImg_brush.png";
            break;
        case 'bottle-glue':
            reqName = t("requirements.bottle-glue");
            reqImg = "/assets/icons/reqImg_bottle-glue.png";
            description = t("requirements.bottle-glue-description")
            break;
        case 'stick-glue':
            reqName = t("requirements.stick-glue");
            reqImg = "/assets/icons/reqImg_stick-glue.png";
            break;
        case 'stick':
            reqName = t("requirements.stick");
            reqImg = "/assets/icons/reqImg_stick.png";
            description = t("requirements.stick-description")
            break;
        case 'tush':
            reqName = t("requirements.tush");
            reqImg = "/assets/icons/reqImg_tush.png";
            break;
        case 'crayon':
            reqName = t("requirements.crayon");
            reqImg = "/assets/icons/reqImg_crayon.png";
            break;
        case 'pencil-color':
            reqName = t("requirements.pencil-color");
            reqImg = "/assets/icons/reqImg_pencil-color.png";
            break;
        case 'pencil':
            reqName = t("requirements.pencil");
            reqImg = "/assets/icons/reqImg_pencil.png";
            break;
        case 'tape':
            reqName = t("requirements.tape");
            reqImg = "/assets/icons/reqImg_tape.png";
        break;
        case 'bristol':
            reqName = t("requirements.bristol");
            reqImg = "/assets/icons/reqImg_bristol.png";
        break;
        case 'cereal':
            reqName = t("requirements.cereal");
            reqImg = "/assets/icons/reqImg_cereal.png";
            description = t("requirements.cereal-description")
        break;
        case 'masking-tape':
            reqName = t("requirements.masking-tape");
            reqImg = "/assets/icons/reqImg_masking-tape.png";
            break;
        case 'cardboard':
            reqName = t("requirements.cardboard");
            reqImg = "/assets/icons/reqImg_cardboard.png";
            break;
        case 'allColors':
            reqName = t("requirements.allColors");
            reqImg = "/assets/icons/reqImg_all-colors.png";
            description = t("requirements.allColors-description")
        break;
        default:
            // Handle the case when 'type' does not match any of the specified cases
            break;
    }

    return (
        <div className="stepRequirements--item">
            <div className="stepRequirements--img">
                <img src={reqImg} alt={reqName} />
            </div>
            <div className="stepRequirements--name">{reqName}</div>
            {showDescription && description && <div className="stepRequirements--description">{description}</div>}
        </div>
    );
}

export default StepRequirement;
