import React from 'react';
import catbearsAngry from './catbears_angry.png';
import catbearsAngry2x from './catbears_angry@2x.png';
import { useTranslation } from 'react-i18next';
import './SomethingWrong.scss'

const handleRefresh = () => {
  // Refresh the page
  window.location.reload();
};

const SomethingWrong = () => {
  const {t} = useTranslation();

  return (
    <div className={"somethingWrong_container"}>
        <img
            alt={`catbearsAngry`}
            src={catbearsAngry}
            srcSet={`${catbearsAngry2x} 2x, ${catbearsAngry2x} 3x`}
            className="catbearsAngry"
            />      
      <h1 className={"somethingWrong_header"}>{t('somethingWrong.title')}</h1>
      <p className={"somethingWrong_message"}>{t('somethingWrong.message')}</p>
      <button onClick={handleRefresh}>{t('somethingWrong.cta')}</button>
    </div>
  );
};

export default SomethingWrong;
