import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import "./Journey.scss";
import CircleProgress from "../../components/CircleProgress/CircleProgress"
import EmojiToImage from "../../utils/EmojiToImage"
import * as ANIMALS from "../../components/Animal/AnimalAssets";
import UserContext from "../Login/UserContext";
import SubscriptionModal from "../../components/SubscriptionModal/SubscriptionModal";
import {buildJourneyFromStepIds, journeyFlowItems} from "./JourneyFlowItems";
import {chain} from "lodash";
import {getPlanFromAIChat} from "../../utils/helpers";


const defaultJourneyStepIds = ['myPointOfView', 'assumePointOfView', 'AIStep']

function JourneyUI({relationshipID, journeyID, quests, name, partnerName, animalId, partnerAnimalId, mySideMirror}) {

  const {t} = useTranslation();
  const {user} = useContext(UserContext);
  const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);

  const [journeyStepIds, setJourneyStepIds] = useState(mySideMirror.journeyStepIds || defaultJourneyStepIds)

  const [journeySteps, setJourneySteps] = useState(
    buildJourneyFromStepIds(journeyStepIds)
  );

  console.log('quests', quests);

  const partnerAnimal = ANIMALS.default[partnerAnimalId];

  const getQuestNumber = (quests, questIdx) => {
    return quests[questIdx].filter((step) => (step.isCompleted)).length;
  }

  const getIsJourneyItemCompleted = (journeyItem, index) => {
    return journeyItem.steps.length / getQuestNumber(quests, index) === 1
  }

  const isJourneyItemDisabled = ({index}) => {
    if (index === 0) return false;
    const previousJourneyItem = journeySteps[index - 1];
    const isPreviousItemsCompleted = getIsJourneyItemCompleted(previousJourneyItem, index - 1)
    return !isPreviousItemsCompleted;
  }

  const shouldTriggerSubscriptionModal = (index) => {
    // return (index === 2 && !user.isSubscribed) //REMOVING PAYWALL 7.10.2023
  }

  console.log('chained', chain(journeyFlowItems).pick(journeyStepIds).value());

  return (
    <>
      <div className="Journey">
        <div className=" CONTENT__list--container CONTENT__list--container--journey">
          {
            journeySteps.map((journeyItem, jIdx) => {
              // journeyItems is quests
              // quests.map(((quest, questIdx) => {
              console.log("journeyItem " + jIdx + " ", journeyItem)

              return <CircleProgress
                isLocked={isJourneyItemDisabled({journeyItem, index: jIdx})}
                onClick={shouldTriggerSubscriptionModal(jIdx) ? () => setSubscriptionModalOpen(false) : null} 
                key={jIdx}
                textBelowProgressCircle={t(`${journeyItem.title}`, {partnerName: partnerName})}
                insideCircle={EmojiToImage(journeyItem.icon, false)}
                color={"mySide"}
                questNumber={getQuestNumber(quests, jIdx)}
                StepsLength={journeyItem.steps.length}
                ContentInsideProgressCircle={"hi"}
                state={journeyItem}
                pathname={`/relationship/${relationshipID}/journey/${journeyID}/quest/${jIdx}`}
                // isSelected={quest.isSelected}
                isSelected={false}
              />
            })
          }
        </div>
      </div>
      <div>
        {getPlanFromAIChat(mySideMirror.AISummary)}
      </div>
      <SubscriptionModal isOpen={isSubscriptionModalOpen} onExit={() => setSubscriptionModalOpen(false)}/>
    </>
  )
}

export default JourneyUI