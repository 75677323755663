import { useEffect } from 'react';


function KeyboardNavigateLesson(selectedValue, setSelectedValue, maxValue, fireFunc) {
  

  const switchArrowKeys = (document.body.getAttribute(`lang`)== "he") 

  useEffect(() => {
    
    const handleKeyDown = (event) => {
      
      event.preventDefault();
      switch (event.key) {
        case 'ArrowLeft':
          switchArrowKeys 
                  ? setSelectedValue((selectedValue < maxValue) ? (selectedValue + 1) : selectedValue)
                  : setSelectedValue((selectedValue > 0) ? (selectedValue - 1) : selectedValue)
          break;
          case 'ArrowRight':
            switchArrowKeys 
                  ? setSelectedValue((selectedValue > 0) ? (selectedValue - 1) : selectedValue)
                  : setSelectedValue((selectedValue < maxValue) ? (selectedValue + 1) : selectedValue)
          break;
        case 'ArrowUp':
          console.log("ArrowUp")
            setSelectedValue((selectedValue > 1) ? (selectedValue - 2) : (selectedValue > 0 ? selectedValue - 1 : 0));
          break;
        case 'ArrowDown':
          console.log("ArrowDown")
            setSelectedValue((selectedValue < maxValue - 1) ? (selectedValue + 2) : maxValue); 
          break;
        case ' ':
        case 'Enter':
          fireFunc();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [maxValue, fireFunc, setSelectedValue]);

  return selectedValue;
}


export default KeyboardNavigateLesson;
