import React from "react";
import './ConflictSummary.scss'
import AnimalsThumbnail from "../AnimalsThumbnail/AnimalsThumbnail";
import * as JourneyFlow from "../../pages/Journey/JourneyFlow.json";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getCardSides} from "../../utils/helpers";

const ConflictSummary = ({
                           mirrorID,
                           relationship,
                           summaryPageRef,
                           summaryFeltImgTempRef,
                           summaryFeltImgRef,
                           html2canvasRef,
                           copyDivToClipboard
                         }) => {
  const {t} = useTranslation();

  if (!relationship || !mirrorID) return null;

  const {
    mySideMirrors,
    name,
    partnerName,
    animalId,
    partnerAnimalId,
    gender,
    partnerGender
  } = relationship;

  const mySideMirror = mySideMirrors.find((m) => m.id === mirrorID);

  const {createdAt} = mySideMirror;
  const formattedCreatedAt = moment(createdAt).format('DD/MM/yyyy');


  return <div className="summary-container ">
    <div>
      <div>AI SUMMARY:</div>
      {mySideMirror.AISummary}
    </div>
    <div className="summary__page summary-section" ref={summaryPageRef}>
      <div className="summary__header summary-padding">
        <p className="summary__title--names">{t(`homepage.meAndPartner`, {
          name: name,
          partnerName: partnerName
        })}</p>
        <h2
          className="summary__title--mirrorName">{mySideMirror.situationName || t(`mirroring.defaultJourneyName`)}</h2>
        <p className="summary__title--date">
          {t("mirroring.mirrirCreatedOn") + " " + formattedCreatedAt}</p>
        <div className="feltImg-container">
          {summaryFeltImgTempRef && <div className="feltImgTemp breathAnimation" ref={summaryFeltImgTempRef}></div>}
          {summaryFeltImgRef && <div className="feltImg fade-in" ref={summaryFeltImgRef}></div>}
          <div
            className={html2canvasRef ? 'html2canvas' : ""}
            ref={html2canvasRef}>
            <AnimalsThumbnail
              thumbnailClassName={"summary--thumbnail"}
              animalId={animalId}
              partnerAnimalId={partnerAnimalId}
              name={name}
              partnerName={partnerName}
            ></AnimalsThumbnail>
          </div>
        </div>
      </div>
      <div>
        {JourneyFlow.journeyItems.map((journeyItem) => {
            console.log(JourneyFlow.journeyItems);
            return (
              <>
                <h1 className="summary__journeyName summary-padding">{t(journeyItem.title, {
                  name: name,
                  partnerName: partnerName
                })}</h1>
                {journeyItem.steps.map((step) => {
                  console.log('stepItems', step.stepItems);
                  if (!step.stepItems) return null;
                  return <>
                    {step.stepItems.map((stepItem) => {
                      if (!stepItem) return null;

                      if (stepItem.type !== "Intro") {
                        if (mySideMirror[stepItem.state]) {
                          console.log('stepItem', stepItem);
                          return (
                            <>
                              <div className="summary__step summary-padding">
                                <p className="summary__stepName"><strong><span
                                  className="hidden">*</span>{t(stepItem.label, {
                                  name: name,
                                  partnerName: partnerName
                                })}<span className="hidden">*</span></strong></p>
                                {stepItem.type === "TextArea" ?
                                  <div className="summary__textArea">{mySideMirror[stepItem.state]}</div> : ""}
                                {stepItem.type === "FeelsCard" ? <div
                                  className="summary-cards summary_cards--feel">{mySideMirror[stepItem.state].map((card) => {
                                  return (
                                    <>
                                      <table className="summary_card summary_card--feel" cellSpacing="0"
                                             cellPadding="0" border="0">
                                        <tr>
                                          <td className="card__emoji card--cell" width="auto">{card.emoji}</td>
                                          <td className="card__modifier card--cell"
                                              width="auto">{(card.modifier === `isReduced` && t(`card.alittle`) ? t(`card.alittle`) : "") + (card.modifier === `isReversed` && t(`card.not`) ? t(`card.not`) : "")}</td>
                                          <td className="card--cell"
                                              width="auto">{t(`cards.FEEL.${card.title}.title`)}</td>
                                        </tr>
                                      </table>
                                    </>
                                  )

                                })}</div> : ""}

                                {stepItem.type === "NeedCard" ? <div
                                  className="summary-cards summary_cards--need">{mySideMirror[stepItem.state].map((card) => {
                                  return (
                                    <>
                                      <table className="summary_card summary_card--need" cellSpacing="0"
                                             cellPadding="0" border="0">
                                        <tr>
                                          <td className="card__emoji card--cell" width="auto">{card.emoji}</td>
                                          <td className="card__modifier card--cell"
                                              width="auto">{(card.modifier === `isReduced` && t(`card.alittle`) ? t(`card.alittle`) : "") + (card.modifier === `isReversed` && t(`card.not`) ? t(`card.not`) : "")}</td>
                                          <td className="card--cell"
                                              width="auto">{t(`cards.NEED.${card.title}.title`)}</td>
                                        </tr>
                                      </table>
                                    </>
                                  )

                                })}</div> : ""}
                                {stepItem.type === "HappenedCard" ?
                                  <div
                                    className="summary-cards summary_cards--happened">{mySideMirror[stepItem.state].map((card) => {
                                    //for happened cards
                                    const {sourceGender, targetName, sourceName} = getCardSides(
                                      {switchSides: card.switchSides, name, partnerName, gender, partnerGender}
                                    );

                                    return (
                                      <>
                                        <table className="summary_card summary_card--happened" cellSpacing="0"
                                               cellPadding="0" border="0">
                                          <tr>
                                            <td className="card__emoji card--cell" width="auto">{card.emoji}</td>
                                            <td className="card__name card--cell" width="auto">{sourceName}</td>
                                            <td className="card__modifier card--cell"
                                                width="auto">{(card.modifier === `isReduced` && t(`card.alittle`) ? t(`card.alittle`) : "") + (card.modifier === `isReversed` && t(`card.not`) ? t(`card.not`) : "")}</td>
                                            <td className="card--cell"
                                                width="auto">{t(`cards.HAPPENED.${card.title}.title`, {context: sourceGender})}</td>
                                            <td className="card--cell"
                                                width="auto">{t(`cards.HAPPENED.${card.title}.prefix`)}</td>
                                            <td className="card__name card--cell" width="auto">{targetName}</td>
                                          </tr>
                                        </table>
                                      </>
                                    )
                                  })}</div> : ""}

                                <br/>
                              </div>
                            </>
                          )
                        }
                      }
                    })
                    }
                  </>
                })}
              </>
            )
          }
        )}
      </div>
      <br/><br/>
      <div className="summary-footer">
        <p>{t('general.createdWithFelt')}</p>
        <a href="https://www.felt.catbears.com">https://www.felt.catbears.com</a>
      </div>
    </div>
    {copyDivToClipboard &&
      <a className="copytoClipboard" onClick={() => copyDivToClipboard()}>
        <button>
          <img src="/assets/icons/copytoclipboard.png"/>
        </button>
        {t('general.copyToClipboard')}
      </a>
    }
  </div>
}

export default ConflictSummary