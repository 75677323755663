import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import Xbutton from "components/Xbutton";
import Instructor from '../../components/Instructor/Instructor';
import StepRequirement from 'components/StepRequirement/StepRequirement';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import './LessonLobby.scss'
import ReactPlayer from 'react-player';
import printFunc from 'utils/printFunc';

const LessonLobby = ({
            pathName, 
            activityPages,
            teaserThumbnail, 
            name, 
            addEvent,
            description, 
            teaserUrl, 
            instructor, 
            AnimalType, 
            instructorName, 
            stepRequirements, 
            onContinue, 
            VolumeSwitchState,
            onXbutton,
            LessonStarted
    }) => {
    const {t} = useTranslation();
    setPropertyVh();

    const onExit = () => {
        setVideoPlayer(false)
    }

    const onOpenVideo = () => {
        setVideoPlayer(true)
    }
     
    const handleOnPrint = () => {
        printFunc(activityPages)
    }
    
    const handleOnContinue = () => {
        LessonStarted ? onContinue() : onOpenVideo()
    }

    const [videoPlayer, setVideoPlayer] = useState(false)
    return (
        <>

        {videoPlayer && 
            <div className="lessonLobby-container--video">
                <VideoPlayer
                    URL={teaserUrl}
                    onExit={onExit}
                    onContinue={LessonStarted ? onExit : onContinue}
                    IsCurrentSelectedStepItemCompleted={true}
                    volume={VolumeSwitchState}
                    VideoPlayerLoop={false}
                />
          </div>
          }     
          {!videoPlayer &&  
          <>              
        <Xbutton fixed={true} onClose={onXbutton ? onXbutton : undefined}/>
        <div className={"lessonLobby-container"}>
            <div className={"lessonLobby-content noselect"}>
                <div className={"lessonLobby-content-a"}>
                    <div className={"lobby-title"}>{name}</div>
                    {/* <div className={"lobby-subtitle"}>{description}</div> */}
                    {LessonStarted && 
                        <div className="teaserThumbnail" onClick={onOpenVideo}>
                        {/* <div className="teaserThumbnail-instructor">
                            <Instructor 
                                    AnimalType ={AnimalType} 
                                    instructorName ={instructorName}
                                    intructorBottom = "-84px"
                                    intructorSize = "280px"
                                    intructorNameSize = "20px"
                                    // intructorNameColor = "red"
                                    intructorNameMargin = "0px"
              
                                />
                        </div> */}
                        <div className="teaserThumbnail-btn">
                            <div className="teaserThumbnail-btn_img">
                                <img className="teaserThumbnail-btn_img" src="/assets/icons/play-icon.png" />
                            </div>
                            <div className="teaserThumbnail-btn_txt">{t("general.watchIntro")}</div>
                        </div>
                        <div className="teaserThumbnail-thumb">
                            <img src={teaserThumbnail} alt="video thumbnail" />
                        </div>
                    </div>}
                </div>
                <div className={"lessonLobby-content-b"}>
                    <div className={"content-b_lobby-title"}>{t("general.prepForLesson")}</div>
                    {/* <div className={"content-b_lobby-subtitle"}>{description}</div> */}
                    {/* <div className={"content-b_requirements"}> */}
                    <div className="stepRequirements--Lobby-container">
                        {stepRequirements.map((Requirement, index) => {
                                return (
                                    <StepRequirement
                                        key={index}
                                        type={Requirement.name}
                                        showDescription
                                    />
                                )
                            })}
                    </div>
                    {/* <div>
                        <div>title what you need</div>
                        <div>tip</div>
                        <div>tip</div>
                        <div>tip</div>
                    </div> */}
                </div>
            </div>
            <div className={"lessonLobby-footer"}>
                <div className={"lessonLobby-footer-2buttons"}>
                {/* {LessonStarted &&  <button onClick={onOpenVideo} className={" lessonLobby-button lessonLobby-button-secondary catbearsCTA-secondary lessonLobby-button"}>
                    {t("general.watchIntro")}
                </button>} */}
                <button onClick={handleOnPrint} className={" lessonLobby-button lessonLobby-button-secondary catbearsCTA-secondary lessonLobby-button"}>
                    <img src="/assets/icons/icon-print.svg" />
                    {t("general.printActivityPages")}
                </button>          
                <button onClick={handleOnContinue} className={" lessonLobby-button lessonLobby-button-primary catbearsCTA-primary"}>
                    {LessonStarted ? t("general.backToLesson"): t("general.startLesson")}
                </button>
                </div>
            </div>
        </div>
        </>
        }
        </>
        )

}

export default LessonLobby