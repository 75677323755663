/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserByEmailQuery = `
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      email
      isSubscribed
      userProgress
      createdAt
      updatedAt
      locale
      registered
      type
      exitPoll {
        reasons
        subscriptionLikelihood
        explanation
      }
      relationships {
        id
        createdAt
        updatedAt
        name
        animalId
        gender
        partnerAnimalId
        partnerGender
        relation
        partnerName
        mySideMirrors {
          id
          whatHappened
        }
      }
    }
  }
`;

export const getUser = /* GraphQL */ `
  query {
    user {
      id
      username
      email
      registered
      locale
      isSubscribed
      userProgress
      type
    }
  }
`;

export const getUserByIdQuery  = /* GraphQL */ `
  query GetUserByIdQuery($id: String) {
    getUserByIdQuery(id: $id) {
      id
      username
      email
      registered
      locale
      isSubscribed
      userProgress
      type
      isGuest
      isOnboardingComplete
    }
  }
`;

export const getRelationship = /* GraphQL */ `
  query GetRelationship($id: String) {
    getRelationship(id: $id) {
      id
      createdAt
      updatedAt
      name
      animalId
      gender
      partnerAnimalId
      partnerGender
      relation
      partnerName  
      mySideMirrors{
        id
        relationshipId
        AISummary {
          role
          content
        }
        progress{
          journeyItem
          step
          version
          currentStepNumber
          flowState{
            isCompleted
          }
        }
        createdAt
        updatedAt
        journeyProps
        journeyStepIds
      } 
    }
  }
`;

export const getMySideMirror = `
  query GetMirror($id: String) {
    getMirror(id: $id) {
      id
      relationshipId
      progress{
        journeyItem
        step
        version
        currentStepNumber
        flowState{
          isCompleted
        }
      }
      createdAt
      updatedAt
      journeyProps
      journeyStepIds
    } 
  }
`;

export const mySideMirror = `
mySideMirrors {
  id
  createdAt
  updatedAt
  journeyProps
  journeyStepIds
}
`



export const apiListRelationships = `
  query listRelationships{
    listRelationships{
      relationships{
        id
        createdAt
        updatedAt
        name
        animalId
        gender
        partnerAnimalId
        partnerGender
        relation
        partnerName
      }
    }
  }
`
export const listRelationships = /* GraphQL */ `
  query ListRelationships(
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelationships(filter: $filter, l§imit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        animalId
        gender
        partnerAnimalId
        partnerGender
        relation
        partnerName
        owner {
          id
          username
          email
          registered
          locale
          relationships {
            items {
              id
              createdAt
              updatedAt
              name
              animalId
              gender
              partnerAnimalId
              partnerGender
              relation
              partnerName
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              mySideMirrors {
                id
                createdAt
                updatedAt
                journeyProps
                journeyStepIds
              }
            }
            nextToken
          }
          playlists {
            items {
              id
              createdAt
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              videos {
                url
                start
                end
                title
              }
              name
              order
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        partnerRelationshipSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        partnerTriggerSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        relationshipSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        triggerSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        mySideMirrors {
          id
          createdAt
          updatedAt
          journeyProps
          journeyStepIds
        }
      }
      nextToken
    }
  }
`;
export const getPlaylist = /* GraphQL */ `
  query GetPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              journeyProps
              journeyStepIds
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;
export const listPlaylists = /* GraphQL */ `
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        owner {
          id
          username
          email
          registered
          locale
          relationships {
            items {
              id
              createdAt
              updatedAt
              name
              animalId
              gender
              partnerAnimalId
              partnerGender
              relation
              partnerName
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              mySideMirrors {
                id
                createdAt
                updatedAt
                journeyProps
                journeyStepIds
              }
            }
            nextToken
          }
          playlists {
            items {
              id
              createdAt
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              videos {
                url
                start
                end
                title
              }
              name
              order
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        videos {
          url
          start
          end
          title
        }
        name
        order
        updatedAt
      }
      nextToken
    }
  }
`;
