import React , {useState, useEffect} from "react";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import setPropertyVh from '../../utils/setPropertyVh';
import { useTranslation } from 'react-i18next';
import "./Teamwork.scss"
import ReactPlayer from 'react-player';
import Titleblock from "components/PageBlocks/TitleBlock";
import FooterStickyBlock from "components/PageBlocks/FooterStickyBlock";
import PageWrapperBlock from "components/PageBlocks/PageWrapperBlock";
import ContentWrapperBlock from "components/PageBlocks/ContentWrapperBlock";
import BulletsBlock from "components/PageBlocks/BulletsBlock";
import Instructor from "components/Instructor/Instructor";


const Teamwork = ({
    URL, 
    playRefAudio,
    pauseRefAudio,
    teamworkMusicRef,
    addEvent,
    onContinue,
    onBack, 
    CurrentSelectedStepItem,
    bullets,
    AnimalType,
    instructorName,
    }) => {
    
          
    useEffect(() => {
        playRefAudio(teamworkMusicRef, true);
    }, []);
    
        
    setPropertyVh();
    const {t} = useTranslation();
    const [CHEATS, setCHEATS] = useState(0)
    // playRefAudio(teamworkMusicRef, true)

    function handleContinueTeamwork () {
        addEvent("continue", "stepItem", "Teamwork", CurrentSelectedStepItem)
        onContinue()
    }

    function handleOnBack () {
        addEvent && addEvent("back", "stepItem", "Teamwork", CurrentSelectedStepItem);
        onBack()
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
        event.preventDefault();
          switch (event.key) {
            case ' ':
            case 'Enter':
              handleContinueTeamwork();
              break;
            case 'Delete':
            case 'Backspace':
              handleOnBack();
              break;
            default:
              break;
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
      return (
          <>
          <PageWrapperBlock 
            backgroundColor = "var(--page-background-color)"
            ClassName={"teamwork"}
          >
            <ContentWrapperBlock
                ContentMaxWidth={"900px"}
                ContentPadding={"0 20px"}
                
            >
                <Titleblock 
                    title={t("general.letsCreate")}
                />
                <div className={"ContentWrapperBlock-flex-column"}>
                    <div>
                        <div className="ContentWrapperBlock-video-container">  
                                <ReactPlayer
                                    id="teamwork-player"
                                    url={URL}
                                    seek={0}
                                    playing
                                    loop
                                    volume={0}
                                    muted={true}
                                    onBuffer={() => console.log('Buffering...')}
                                    onBufferEnd={() => console.log('Buffering ended')}
                                />
                            <div className="teaserThumbnail-instructor">
                                <Instructor 
                                    AnimalType ={AnimalType}
                                    instructorName ={instructorName}
                                    intructorBottom = "-70px"
                                    intructorSize = "250px"
                                    intructorNameSize = "15px"
                                    // intructorNameColor = "red"
                                    intructorNameMargin = "-10px"
                                 />
                            </div>
                        </div> 
                    </div>
                    <BulletsBlock
                        fontSize={"43px"} 
                        bullets={bullets}
                    />
                </div>
            </ContentWrapperBlock>
            <FooterStickyBlock 
                    button1={{ text: t("general.replay"), className: "catbearsCTA catbearsCTA-primary", onClick: handleOnBack, iconStart: "/assets/icons/icon_rewind.svg" }}
                    button2={{ text: t("general.continue"), className: "catbearsCTA catbearsCTA-primary", onClick: handleContinueTeamwork, iconEnd: "/assets/icons/icon_arrow-forward.svg" }}
                    backgroundColor = "var(--page-background-color)"
                    backgroundBright
                />
          </PageWrapperBlock>
        </>
    )
}

export default Teamwork
