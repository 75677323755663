import React from "react";
import "./FooterStickyBlock.scss"

// ! needs to be inside a wrapper with 100vh

const FooterStickyBlock = ({button1, button2, button3, backgroundColor, backgroundBright}) => {

    backgroundColor && document.documentElement.style.setProperty("--FooterStickyBlock-background-color", backgroundColor );

    return(
        <>
        <div className={"FooterStickyBlock-container " + (backgroundBright && "FooterStickyBlock-backgroundBright")}>
        {button1 && 
            <button className={button1.className} onClick={button1.onClick}>
                {button1.iconStart && <img src={button1.iconStart} alt="icon"/>}
                <div className="text">{button1.text}</div>
                {button1.iconEnd && <img src={button1.iconEnd} alt="icon"/>}
            </button>
        }
        {button2 && 
            <button className={button2.className} onClick={button2.onClick}>
                {button2.iconStart && <img src={button2.iconStart} alt="icon"/>}
                <div className="text">{button2.text}</div>
                {button2.iconEnd && <img src={button2.iconEnd} alt="icon"/>}
            </button>
        }

        {/* <button className="catbearsCTA " onClick={handleOnBack}>
            <div className="text">{t("general.replay")}</div>
            <div className="icon icon-rtl"><img className="video-controlls__img" src="/assets/icons/icon_rewind.svg" alt="catbears_video_control_icon"/></div>
        </button>
            <button className="catbearsCTA " onClick={handleContinueTeamwork}>
            <div className="text">{t("general.continue")}</div>
            <div className="icon icon-rtl"><img className="video-controlls__img" src="/assets/icons/icon_arrow-forward.svg" alt="catbears_video_control_icon"/></div>    
        </button> */}
        
        </div>
        </>
    )
}

export default FooterStickyBlock;