import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
import Backgrounds from 'components/Backgrounds/Backgrounds';
import './HeroPoster.scss';

function HeroPoster({pathname, isCompleted, topLine, name, description, imgPath}){
    const {t} = useTranslation();
    return(
        <>
        <div className="HeroPoster__container--list-item texture ">
            <Backgrounds
				name={'Room'}
			/>
        <div className="HeroPoster__container ">
          <div className="HeroPoster__container--b">
            <div className="HeroPoster__container--text ">
                {/* <p className="HeroPoster__topLine">{topLine}</p> */}
                <div className="HeroPoster__title">{name}</div>            
                <div className="HeroPoster__description">{description}</div>            

                </div>
                <img
                    className="instructor "
                    alt={`meek peek pointing`}
                    src={"/assets/course/meek_peak_pointing.png"}
                />
                <Link
                    to={{
                    pathname: pathname,
                    isCompleted, isCompleted
                    }}
                >
                <button className={"HeroPoster__button backgroundGlow " + (isCompleted ? " HeroPoster__button--completed " : "")}>{isCompleted ? t("general.doItAgain") : t("general.lets-begin")}</button>
                </Link>
          </div>
          <div className="HeroPoster__container--a">
            <img
                className="HeroPoster__container--image"
                alt={`catbears language`}
                src={imgPath}
            />
          </div>
        </div>
                
    </div>
    </>
    )
}


export default HeroPoster;