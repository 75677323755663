import React, {useContext, useState, useEffect, useRef, useCallback} from "react";
import CourseTheatreReicpe from "../../flows/CourseTheatreRecipe";
import {useMutation} from 'react-apollo';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import UserContext from "pages/Login/UserContext";
import {updateUserProgress} from '../../graphql/mutations';
import TopNavbar from 'components/TopNavbar/TopNavbar';
import LessonListItem from 'components/LessonListItem/LessonListItem';
import StepRequirement from "components/StepRequirement/StepRequirement";
import LessonPanel from "./LessonPanel"
import LessonLobby from "./LessonLobby"
import LessonStepper from "./LessonStepper"
import LessonStepItem from "./LessonStepItem";
import SomethingWrong from 'components/SomethingWrong/SomethingWrong';
import CheatsListener from "utils/hooks/CheatsListener";
import { GlobalContext } from "context/GlobalContext";
import './Lesson.scss';
import {
  getProgressAsArr, 
  generateProgressTree, 
  generateProgressTreeFromArray, 
  getAllTreeItems,
  checkForProgressInLesson
} from "utils/courseFunctions"

setPropertyVh();

function Lesson({user, match}) {  
    const {params : {lessonName}} = match;
    const { handleUserUpdate } = useContext(UserContext);  
    const [updateUser, { data, loading, error }] = useMutation(gql(updateUserProgress), {
        update(cache, { data: {setUserProgress } }) {
            if (!setUserProgress) {
                console.error('setUserProgress is undefined or null');
                return;
            }
            handleUserUpdate(setUserProgress);
        },
    });


    const UpdateUserProgressFunc = async (someProgress) => {
      try {
        await updateUser({ variables: { userProgress: someProgress } });
        console.log('5. User progress updated successfully.');
        console.log(someProgress);
        } catch (error) {
        console.error('Error updating user progress:', error);
      }
    };

    const handleCloseLobby = () => {
      setPageState("LessonPanel")
    }
    const handleOpenLobby = () => {
      // setOpenedLobbyFromPanel(true)
      setPageState("LessonLobby")
    }

    const [Loading, setLoading] = useState(false); // State to manage loading
    const tempProg =  generateProgressTree(CourseTheatreReicpe)
    console.log("user.userProgress!! ", user.userProgress)
    const [UserProgress, setUserProgress] = useState((Object.keys(user.userProgress).length ? user.userProgress : tempProg));
    
    let [highestCourseIndex, highestLessonIndex, highestStepIndex, highestStepItemIndex] = getProgressAsArr(UserProgress)
    const [StepIndToCelebrate, setStepIndToCelebrate] = useState(0)
    const [StepItemProgIndex, setStepItemProgIndex] = useState(highestStepItemIndex);
    const [StepProgIndex, setStepProgIndex] = useState(highestStepIndex);
    const [CurrentSelectedStepItem, setCurrentSelectedStepItem ] = useState(0)
    
    const [currentLessonRecipe, setCurrentLessonRecipe] = useState(CourseTheatreReicpe.lessons.find(lesson => lesson.pathname === lessonName))
    const [CurrentLessonIndex, setCurrentLessonIndex] = useState(CourseTheatreReicpe.lessons.findIndex(lesson => lesson.pathname === currentLessonRecipe.pathname))
    const [LessonProgIndex, setLessonProgIndex] = useState(highestLessonIndex)
    const [CurrentSelectedStep, setCurrentSelectedStep ] = useState((LessonProgIndex > CurrentLessonIndex) ? 0 : highestStepIndex)
    const [CourseProgIndex, setCourseProgIndex] = useState(highestCourseIndex);//hardcoded now
    
    const [PauseEventsHandle, setPauseEventsHandle] = useState(false)
    const [IsCurrentSelectedStepItemCompleted, setIsCurrentSelectedStepItemCompleted ] = useState(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps[CurrentSelectedStep].stepItems[CurrentSelectedStepItem].completed)     
    const [LessonStarted, setLessonStarted] = useState(checkForProgressInLesson(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex]));

    const resetPageStates = () => {
      setMaxCourses(UserProgress.courses.length)
      setMaxLessons(UserProgress.courses[CourseProgIndex].lessons.length)
      setMaxSteps(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps.length)
      setMaxStepItems(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps[CurrentSelectedStep <= currentLessonRecipe.steps.length - 1 ? CurrentSelectedStep : currentLessonRecipe.steps.length - 1].stepItems.length )
      setStepsRecipe(currentLessonRecipe.steps); 
      console.log("CurrentSelectedStep ", CurrentSelectedStep)
      setCurrentLessonIndex(CourseTheatreReicpe.lessons.findIndex(lesson => lesson.pathname === currentLessonRecipe.pathname))
      setStepRequirements((stepsRecipe[CurrentSelectedStep] && stepsRecipe[CurrentSelectedStep].requirements) ? stepsRecipe[CurrentSelectedStep].requirements : null);
      setStepName(t((stepsRecipe[CurrentSelectedStep] && stepsRecipe[CurrentSelectedStep].name) ? stepsRecipe[CurrentSelectedStep].name : ""));
      setStepDescription(t((stepsRecipe[CurrentSelectedStep] && stepsRecipe[CurrentSelectedStep].description) ? stepsRecipe[CurrentSelectedStep].description : ""));
      setIsCurrentSelectedStepItemCompleted(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps[CurrentSelectedStep].stepItems[CurrentSelectedStepItem].completed)
      setLessonStarted(checkForProgressInLesson(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex]));
    }

    const getLessonRequirementsArr = () => {
      const LessonRequirementsArr = [];
      console.log("stepsRecipe: ", stepsRecipe);
      
      stepsRecipe.forEach(step => {
        if (Array.isArray(step.requirements)) {
          step.requirements.forEach(requirement => {
            if (!LessonRequirementsArr.some(existingReq => existingReq.name === requirement.name)) {
              LessonRequirementsArr.push(requirement);
            }
          });
        }
      });
    
      return LessonRequirementsArr;
    };
    
    
    

    const [MaxCourses, setMaxCourses] = useState(UserProgress.courses.length)
    const [MaxLessons, setMaxLessons] = useState(UserProgress.courses[CourseProgIndex].lessons.length)
    const [MaxSteps, setMaxSteps] = useState(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps.length)
    const [MaxStepItems, setMaxStepItems] = useState(UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps[CurrentSelectedStep <= currentLessonRecipe.steps.length-1 ? CurrentSelectedStep : currentLessonRecipe.steps.length - 1].stepItems.length);

    useEffect(() => {
      resetPageStates() // important

    }, [CurrentSelectedStepItem, CurrentSelectedStep, IsCurrentSelectedStepItemCompleted]);
    

    const [CHEATS, setCHEATS] = useState(0)
    const [CHEATSprogress, setCHEATSprogress] = useState([CourseProgIndex, LessonProgIndex, StepProgIndex, StepItemProgIndex])

    const cheatsOn = () => {
      setCHEATS(true)
    }
    const cheatsOff = () => {
      setCHEATS(false)
    }

    CheatsListener(cheatsOn)

    
    
    
    const renderStepItemsStatus = (items) => {
      const courses = [];
    
      items.forEach((item) => {
        if (item.type === "course") {
          courses.push({
            ...item,
            lessons: [],
          });
        } else if (item.type === "lesson") {
          const course = courses[courses.length - 1];
          if (course) {
            course.lessons.push({
              ...item,
              steps: [],
            });
          }
        } else if (item.type === "step") {
          const course = courses[courses.length - 1];
          const lesson = course?.lessons[course.lessons.length - 1];
          if (lesson) {
            lesson.steps.push({
              ...item,
              stepItems: [],
            });
          }
        } else if (item.type === "stepItem") {
          const course = courses[courses.length - 1];
          const lesson = course?.lessons[course.lessons.length - 1];
          const step = lesson?.steps[lesson.steps.length - 1];
          if (step) {
            step.stepItems.push(item);
          }
        }
      });
    
      const renderStepItems = (stepItems, isCurrent) => {
        return stepItems.map((item, index) => (
          <>
          <div className={ isCurrent && index === CurrentSelectedStepItem ? "CHEATS-underline" : ""} key={index}>
            {item.completed ? "🟢" : "⚪"}
          </div>
          </>
        ));
      };
    
      const renderSteps = (steps, isCurrent) => {
        return steps.map((item, index) => (
          <div className={"CHEATS-flex-row"} key={index}>
            
            {renderStepItems(item.stepItems, isCurrent && index === CurrentSelectedStep)}
            {item.completed ? (
              <span style={{ color: '#139c11' }}>{index}</span>

            ) : (
              <span style={{ color: '#cbc8c8' }}>{index}</span>
            )}
            
            &nbsp;&nbsp;
          </div>
        ));
      };
    
      const renderLessons = (lessons) => {
        return lessons.map((item, index) => (
          <div className={"CHEATS-flex-row "} key={index}>
            {renderSteps(item.steps, CurrentLessonIndex === index)}
            🥇
            {item.completed ? (
              <span style={{ color: '#139c11' }}>{index}</span>

            ) : (
              <span style={{ color: '#cbc8c8' }}>{index}</span>
            )}
          </div>
        ));
      };
    
      const renderCourses = (courses) => {
        return courses.map((item, index) => (
          <div key={index}>
            {renderLessons(item.lessons)}
            {item.completed ? "🏆!" : "🏆"}
          </div>
        ));
      };
    
      return <>{renderCourses(courses)}</>;
    };
    
      
    const plusMinusCHEATSprogress = (index, plusMinus) => {
        const newProgress = [...CHEATSprogress]; // Create a copy of the state array
        if (plusMinus === '+') {
          newProgress[index] += 1; // Increment the specified index value
        } else if (plusMinus === '-') {
          if (newProgress[index] > 0) {
            newProgress[index] -= 1; // Decrement the specified index value
          }
        }
        setCHEATSprogress(newProgress); // Set the new state
    };



   const {VolumeSwitchState, setVolumeSwitchState} = useContext(GlobalContext);

   console.log("VolumeSwitchState :: " , VolumeSwitchState)
    const teamworkMusicRef = useRef();
    const stepCompleteSfxRef = useRef();
    const lessonCompleteSfxRef = useRef();
    const [IsMuteAllSounds, setIsMuteAllSounds] = useState(true)

    const [IsStepCelebrationStepCompleteClass, setIsStepCelebrationStepCompleteClass] = useState("");

  const initialEvents = [];
  const [eventsArr, setEventsArr] = useState(initialEvents);
  const [isProcessingEvent, setIsProcessingEvent] = useState(false);


  const calcNewProgress = (event) => {
    let newProgress = {...JSON.parse(JSON.stringify(UserProgress))};
   
    const stepItemCompleted = () => {
      console.log('🟢 stepItem ' + StepItemProgIndex + " completed");
      newProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps[StepProgIndex].stepItems[StepItemProgIndex].completed = true;
      console.log("3. update DB with newProgress: ", newProgress);
      setUserProgress(newProgress); 
      
      if((MaxStepItems - 1) > (StepItemProgIndex)){
        console.log("4. Add 1 to Step Item: ", StepItemProgIndex);
        const holder = StepItemProgIndex + 1
        setStepItemProgIndex((holder));
        setCurrentSelectedStepItem((holder));
      } else if((MaxStepItems - 1) <= (StepItemProgIndex)){
        console.log("4!. Add event -> 1");
        setStepItemProgIndex(0);
        setCurrentSelectedStepItem(0)
        addEvent("completed" ,"step", event.componentName, StepProgIndex); 
        setPageState("EmptyPage")//
      }
      
    };
  
    const stepCompleted = () => { 
      console.log('⭐ step ' + StepProgIndex + " completed");
      newProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps[StepProgIndex].completed = true;
      setUserProgress(newProgress); 
      if((MaxSteps - 1) > StepProgIndex){
        const holder = StepProgIndex + 1
        setStepProgIndex(holder);
        setCurrentSelectedStep(holder)
      } else if ((MaxSteps - 1) <= StepProgIndex){
        setStepItemProgIndex(0)  
        setStepProgIndex(0);
        addEvent("completed", "lesson", event.componentName, LessonProgIndex); 
      }
      setStepIndToCelebrate(StepProgIndex)

    };
  
    const lessonCompleted = () => {
      console.log('🥇 lesson ' + CurrentLessonIndex + " completed");
      
      newProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].completed = true;
      setUserProgress(newProgress); 
      if((MaxLessons - 1) > CurrentLessonIndex){
        setLessonProgIndex(CurrentLessonIndex + 1);
        } else if(MaxLessons - 1  <= CurrentLessonIndex){
        setStepItemProgIndex(0)  
        setStepProgIndex(0);
        addEvent("completed","course"); 
      }
    };
  
    const courseCompleted = () => {
      console.log('🏆 course ' + CourseProgIndex + " completed");
      newProgress.courses[CourseProgIndex].completed = true;
      if(MaxCourses <= CourseProgIndex + 1){
        setCourseProgIndex(CourseProgIndex + 1);
        setStepItemProgIndex(0)
        setStepProgIndex(0);
        setLessonProgIndex(0)
        everythingCompleted();
      }
      setStepItemProgIndex(0);
      setStepProgIndex(0);
      setLessonProgIndex(0);
      addEvent("completed","everything"); 
    };
  
    const everythingCompleted = () => {
      console.log("EVERYTHING COMPLETED");
    };
  
    switch (event.element) {
      case 'course':
        courseCompleted();
        break;
      case 'lesson':
        lessonCompleted();
        break;
      case 'step':
        stepCompleted();
        break;
      case 'stepItem':
        stepItemCompleted();
        break;
      default:
        break;
    }
  
    return newProgress;
  };
  
  
  const handleEvents = useCallback(async (event) => {
    console.log("2. handeling Event: ", event);
        
      switch (event.type) {
        case 'continue':
        pauseRefAudio(teamworkMusicRef, true)
    await removeEventFromArr(event);
    console.log("current: " , CourseProgIndex,CurrentLessonIndex,CurrentSelectedStep,CurrentSelectedStepItem)
    if (UserProgress.courses[CourseProgIndex].lessons[CurrentLessonIndex].steps[CurrentSelectedStep].stepItems[CurrentSelectedStepItem].completed) {
        console.log('%c Continue ', 'background: blue; color: white;');
        // if the stepItem is completed, we just need to move 1 stepItem forward:
        
        
        if (MaxStepItems > CurrentSelectedStepItem + 1) {
            setCurrentSelectedStepItem((CurrentSelectedStepItem + 1));
            console.log("here");
            
        } else {
            console.log("done stepItems");
            setCurrentSelectedStepItem(0);
            console.log("MaxSteps", MaxSteps);
            console.log("CurrentSelectedStep + 1", CurrentSelectedStep + 1);
            if (MaxSteps > CurrentSelectedStep + 1) {
                console.log("move to next step?");
                setCurrentSelectedStep(CurrentSelectedStep + 1);
                setPageState("LessonPanel");
            } else {
                console.log("move to next lesson?");
                if (LessonProgIndex < UserProgress.courses[CourseProgIndex].lessons.length - 1) {
                    setLessonProgIndex(LessonProgIndex + 1);
                    setCurrentSelectedStep(0);
                    setPageState("LessonPanel");
                } else {
                    console.log("All lessons completed");
                    setPageState("CourseCompleted");
                }
            }
        }
    } else {
        // if the stepItem isn't completed, let's make it completed:
        addEvent("completed", event.element, event.componentName, event.index);
        console.log('%c completed ', 'background: gold; color: red;');
    }
    break;

        case 'completed':
          pauseRefAudio(teamworkMusicRef, true)
          try {
            if(event.element == "step"){
              handleStepCelebrationModal();
            }
            if(event.element == "lesson"){
              handleLessonCelebrationModal();
            }
            const NewProgress = calcNewProgress(event);
            // Perform async action, such as updating the DB
            await UpdateUserProgressFunc(NewProgress);
            await removeEventFromArr(event)

          } catch (error) {
            console.error('Error handling event:', error);
          }
          break;
          case 'back':
            pauseRefAudio(teamworkMusicRef, true)
            if((CurrentSelectedStepItem) > 0 ){
              setCurrentSelectedStepItem((CurrentSelectedStepItem - 1));
            }else{
              console.log("🛑 can't back")
            }
            await removeEventFromArr(event)
          break;
        default:
          break;
      }

  }, [eventsArr]);
  
  const removeEventFromArr = (event) => {
    return new Promise((resolve) => {
      console.log("6. removing the event from eventsArr");
      setEventsArr((prevEvents) => {
        const updatedEvents = prevEvents.filter((e) => e !== event);
        console.log("6b. updatedEvents", updatedEvents);
        resolve(updatedEvents);
        return updatedEvents;
      });
      
      // Continue handling the next event if any
      if (eventsArr.length > 1) {
        processNextEvent();
      } else {
        console.log("7. Finish --------------------")
        setIsProcessingEvent(false); // Stop processing when there are no more events
      }
    });
  };
  

  const processNextEvent = useCallback(() => {
    if (eventsArr.length > 0 && !isProcessingEvent && !PauseEventsHandle){
      setIsProcessingEvent(true);
      handleEvents(eventsArr[0]);
    }
  }, [eventsArr, handleEvents, isProcessingEvent, PauseEventsHandle]);
  
  useEffect(() => {
    handleVolume();

    if (eventsArr.length > 0 && !isProcessingEvent) {
      processNextEvent();
    }
  }, [eventsArr, isProcessingEvent, processNextEvent, PauseEventsHandle]);
  
  const addEvent = (type, element, componentName, index) => {
    const newEvent = { type, element, componentName, index };
    console.log("1. newEvent: ", newEvent);
    setEventsArr((prevEvents) => {
      const updatedEvents = [...prevEvents, newEvent];
      // console.log("Events after adding new event:", updatedEvents);
      return updatedEvents;
    });
    // setAllowEventsHandling(true);
  };
    

    const [PageState, setPageState] = useState(LessonStarted ? 'LessonPanel' : 'LessonLobby');    
    // const [PageState, setPageState] = useState('LessonPanel');    
    // const [PageState, setPageState] = useState('celebrateLesson');    
     
    const handleVolume = () => {
        if(VolumeSwitchState == true){
            document.querySelectorAll("video, audio").forEach((elem) => elem.volume = 1);
        }else {
            document.querySelectorAll("video, audio").forEach((elem) => elem.volume = 0);
        }
    }
  
    const {t} = useTranslation();
    const [stepsRecipe, setStepsRecipe] = useState(currentLessonRecipe.steps); 
    const [stepRequirements, setStepRequirements] = useState((stepsRecipe[CurrentSelectedStep] && stepsRecipe[CurrentSelectedStep].requirements) ? stepsRecipe[CurrentSelectedStep].requirements : null);
    const [stepName,setStepName] = useState(t((stepsRecipe[CurrentSelectedStep] && stepsRecipe[CurrentSelectedStep].name) ? stepsRecipe[CurrentSelectedStep].name : ""));
    const [stepDescription, setStepDescription] = useState(t((stepsRecipe[CurrentSelectedStep] && stepsRecipe[CurrentSelectedStep].description) ? stepsRecipe[CurrentSelectedStep].description : ""));
    const [FadeOutContent, setFadeOutContent] = useState(false)
    
    document.documentElement.style.setProperty("--page-background-color", (currentLessonRecipe.backgroundColor) ? currentLessonRecipe.backgroundColor : "var(--color-skyblue)");
    
    const [StepCelebrationClasses, setStepCelebrationClasses] = useState("")
    const [StepCelebrationState, setStepCelebrationState] = useState("enabled")
 

    const handleStepCelebrationModal = () => {
        setPauseEventsHandle(true)
        setPageState("celebrateStep");
        setIsStepCelebrationStepCompleteClass("")
        setTimeout(() => {
            setTimeout(() => {
                    setPauseEventsHandle(false)
                    setPageState("LessonPanel")  
                    // setToCelebrate(prev => prev.slice(1)); // Remove the celebrated item
            }, 2000);
            setIsStepCelebrationStepCompleteClass("celebration-container--stepItem__completed bounceToLarge")
            setStepCelebrationClasses(" backgroundGlow")
            setStepCelebrationState("completed")
            playRefAudio(stepCompleteSfxRef)
        }, 1400);   
    }

    const handleLessonCelebrationModal = () => {
        setIsStepCelebrationStepCompleteClass("")
        setPageState("celebrateLesson");
        setTimeout(() => {
            setTimeout(() => {
                    // setCelebratingStep(false)
                    setPageState("LessonPanel")  
            }, 3000);
            setIsStepCelebrationStepCompleteClass("celebration-container--stepItem__completed bounceToLarge")
            setStepCelebrationClasses(" backgroundGlow")
            setStepCelebrationState("completed")
            playRefAudio(lessonCompleteSfxRef)
        }, 1400);   
    }     
    
    const handleOpenStepper = () => {
        console.log("open stepper transition")
        setPageState("LessonStepper")  
    }

    const handleCloseStepper = () => {
        console.log("close stepper transition")
        setPageState("LessonPanel")  
    }
        
       

        const playRefAudio = (ref, loop) => {
            ref.current.play();
            if(loop) {
                ref.current.loop = true
            }
            handleVolume()
        }
        const pauseRefAudio = (ref) => {
            ref.current.pause();
            ref.current.currentTime = 0;
            handleVolume()
        }

        let content
        switch (PageState){

            case "LessonLobby" : 
                content = (  
                  <LessonLobby
                    pathName = {lessonName}
                    teaserUrl= {currentLessonRecipe.teaserUrl}
                    teaserThumbnail = {"/" + currentLessonRecipe.imgPath}
                    instructor={currentLessonRecipe.instructor}
                    AnimalType={currentLessonRecipe.AnimalType}
                    instructorName={t(currentLessonRecipe.instructorName)}
                    name={t(currentLessonRecipe.name)}
                    description={t(currentLessonRecipe.description)}
                    stepRequirements = {getLessonRequirementsArr()}
                    onContinue = {handleCloseLobby}
                    VolumeSwitchState= {VolumeSwitchState}
                    activityPages = {currentLessonRecipe.activityPages}
                    addEvent = {addEvent}
                    onXbutton = {LessonStarted ? handleCloseLobby : null}
                    LessonStarted = {LessonStarted}
                  />)
            break
            case "LessonPanel" : 
                content = (  
                    <LessonPanel 
                        FadeOutContent = {FadeOutContent}
                        currentLessonRecipe = {currentLessonRecipe}
                        stepsRecipe = {stepsRecipe}
                        StepProgIndex = {StepProgIndex}
                        MaxSteps = {MaxSteps}
                        AnimalType={currentLessonRecipe.AnimalType}
                        instructorName={t(currentLessonRecipe.instructorName)}
                        CurrentSelectedStep = {CurrentSelectedStep <= currentLessonRecipe.steps.length - 1 ? CurrentSelectedStep : currentLessonRecipe.steps.length - 1}
                        setCurrentSelectedStep = {setCurrentSelectedStep}
                        setCurrentSelectedStepItem = {setCurrentSelectedStepItem}
                        setStepProgIndex = {setStepProgIndex}
                        CurrentLessonIndex = {CurrentLessonIndex}
                        LessonProgIndex = {LessonProgIndex}
                        handleOpenStepper = {handleOpenStepper}
                        stepName = {stepName}
                        handleOpenLobby = {handleOpenLobby}
                        stepDescription = {stepDescription}
                        stepRequirements = {stepRequirements}
                        StepRequirement = {StepRequirement}
                    />
                )   
            break
            
            case "LessonStepper" : 
                content = (  
                    <LessonStepper 
                        CHEATS = {CHEATS}
                        CurrentSelectedStepItem = {CurrentSelectedStepItem}
                        setCurrentSelectedStepItem = {setCurrentSelectedStepItem}     
                        stepRecipe={stepsRecipe[CurrentSelectedStep]}// TODO : fixed and not state!!!
                        handleCloseStepper = {handleCloseStepper}
                        playRefAudio = {playRefAudio}
                        pauseRefAudio = {pauseRefAudio}
                        IsCurrentSelectedStepItemCompleted = {IsCurrentSelectedStepItemCompleted}
                        teamworkMusicRef = {teamworkMusicRef}
                        VolumeSwitchState = {VolumeSwitchState}
                        addEvent = {addEvent}
                        classes = {""}
                        MaxStepItems = {MaxStepItems}
                        AnimalType = {currentLessonRecipe.AnimalType}
                        instructorName = {t(currentLessonRecipe.instructorName)}

                        // FadeOutContent
                    />
                )   
            break
            case "EmptyPage" : 
                content = (
                  <>
                    <div className={"Lesson texutre" }></div>
                  </>
                )
            break
            case "celebrateStep" :
                const isCoin = stepsRecipe[StepIndToCelebrate].coinName
                content = (  
                 <>
                    <div className="celebration-container">
                        <div className={"celebration-container--stepItem celebration-container--stepItem " + (!isCoin && IsStepCelebrationStepCompleteClass)}>
                          <LessonStepItem
                            index = {StepIndToCelebrate}
                            buttonShape={stepsRecipe[StepIndToCelebrate].buttonShape ? stepsRecipe[StepIndToCelebrate].buttonShape : "rectangle"}
                            name = {t(stepsRecipe[StepIndToCelebrate].name)}
                            size = {stepsRecipe[StepIndToCelebrate].size}
                            isCurrent = {false}
                            celebrationMoment = {IsStepCelebrationStepCompleteClass}
                            CelebrationState = {StepCelebrationState}
                            classes = {StepCelebrationClasses}
                            AnimalName = {stepsRecipe[StepIndToCelebrate].AnimalName}
                            coinName={stepsRecipe[StepIndToCelebrate].coinName}
                            StepNumberToShow={stepsRecipe[StepIndToCelebrate].StepNumberToShow ? stepsRecipe[StepIndToCelebrate].StepNumberToShow : null}
                            />
                        </div>
                    </div>
                </>  
                )
            break
            case "celebreateCoin" :
                content = (  
                 <>
                    <div className="celebration-container">
                      {/* <h1>Lesson is complete!</h1> */}
                        <div className={"celebration-container--stepItem celebration-container--stepItem "}>
                          <img className="lesson-step-item--coinImg" src={IsStepCelebrationStepCompleteClass ? stepsRecipe[0].coinImg.ON : stepsRecipe[0].coinImg.OFF} alt="theCatbears" />
                        </div>
                    </div>
                </>  
                )
            break
            case "celebrateLesson" :
                content = (  
                 <>
                    {/* <div className="celebration-container">
                        <div className={"celebration-container--stepItem celebration-container--stepItem "}>
                          <img className="lesson-step-item--coinImg" src={IsStepCelebrationStepCompleteClass ? stepsRecipe[0].coinImg.ON : stepsRecipe[0].coinImg.OFF} alt="theCatbears" />
                        </div>
                    </div> */}
                  <div className={"celebration-container disabledClick " + IsStepCelebrationStepCompleteClass}>
                    <div className="Lessons__list celebration-container--stepItem celebration-container--stepItem ">
                      <LessonListItem
                        key = {CurrentLessonIndex}
                        pathname={currentLessonRecipe.pathname}
                        progressStatus={"completed"}
                        title={t(currentLessonRecipe.name)}
                        description={t(currentLessonRecipe.description)}
                        imgPath={currentLessonRecipe.imgPath}
                        instructor={currentLessonRecipe.instructor}
                        AnimalType={currentLessonRecipe.AnimalType}
                        instructorName={t(currentLessonRecipe.instructorName)}
                        state = {"current"}
                        isCurrent = {true}
                        badge = {currentLessonRecipe.badge}
                      />
                      </div>
                    </div>
                </>  
                )
            break
            default:
              content = (
                <>
                   <SomethingWrong/>
                </>
              )
            break
    
            

    }
    

        return (
            <>
            <TopNavbar
                showNavBG={false}
                removeNavStrip={true}
                showLogo={false}
                authContext={UserContext}
                user={user}
                title={false}
                backgroundColor={false}
                border={false}
                setVolumeSwitchState = {setVolumeSwitchState}
                VolumeSwitchState = {VolumeSwitchState}
        ></TopNavbar>
            <audio
                ref={teamworkMusicRef}
                src='https://media.catbears.com/catbears-teamwork.mp3'
            />
            <audio
                ref={stepCompleteSfxRef}
                src='/assets/audio/Ni Sound - Cute Interface -  Bubble Pop Synth Chord Positive Notification.wav'
            />
            <audio
                ref={lessonCompleteSfxRef}
                src='/assets/audio/Ni Sound - Matching Game - Positive Notification Ascending Synth.wav'
            />
                <>                  
                {CHEATS ? 
                //bubu
                    <>
                    
                    <div className="CHEATS ">   
                       
                      <div>lessonProgress? = {LessonStarted ? "true" : "false"}</div>
                      <div className="CHEATS-miniButton" onClick={cheatsOff}>X</div>
                      {/* <div>VolumeSwitchState:  {VolumeSwitchState ? "true" : "false"}</div> */}

                      <div className="CHEATS-flex-row ">
      
                        {/* <div>PauseEventsHandle: {PauseEventsHandle.toString()}</div> */}
                        <button className="CHEATS-miniButton" onClick={() => console.log("userProgress:", console.log(JSON.stringify(UserProgress, null, 2)))}>🖨️</button>
                        <button className="CHEATS-miniButton" onClick={() => window.location.reload()}>🔄</button>
                        <button 
                            className="CHEATS-miniButton" 
                            onClick={() => {
                              UpdateUserProgressFunc(generateProgressTree(CourseTheatreReicpe))
                            }}
                        >🗑️</button>
                        <button className="CHEATS-miniButton" onClick={() => addEvent("back", "stepItem", "cheat", "")}>👈</button>
                        <button className="CHEATS-miniButton" onClick={() => addEvent("continue", "stepItem", "cheat", "")}>👉</button>
                      </div>
                      <div className="CHEATS-flex-row">
                        <div>
                          <div>Current = {CurrentLessonIndex + "," + CurrentSelectedStep +"," + CurrentSelectedStepItem + "/" + MaxStepItems}</div>
                          <div>Progress = { LessonProgIndex + "," + StepProgIndex +"," + StepItemProgIndex}</div>
                        </div>
                        <div className="CHEATS-flex-row">
                          <div className="CHEATS-flex-row">
                              <div className="CHEATS-flex-column">
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(0,"+")}>+</a>
                                  <span className="CHEATS-miniBox" >{CHEATSprogress[0]}</span>
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(0,"-")}>-</a>
                              </div>
                              <div className="CHEATS-flex-column">
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(1,"+")}>+</a>
                                  <span className="CHEATS-miniBox" >{CHEATSprogress[1]}</span>
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(1,"-")}>-</a>
                              </div>
                              <div className="CHEATS-flex-column">
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(2,"+")}>+</a>
                                  <span className="CHEATS-miniBox" >{CHEATSprogress[2]}</span>
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(2,"-")}>-</a>
                              </div>
                              <div className="CHEATS-flex-column">
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(3,"+")}>+</a>
                                  <span className="CHEATS-miniBox" >{CHEATSprogress[3]}</span>
                                  <a className="CHEATS-miniButton" onClick={()=>plusMinusCHEATSprogress(3,"-")}>-</a>
                              </div>
                          </div>
                          <button onClick={() => {
                            UpdateUserProgressFunc(generateProgressTreeFromArray(CourseTheatreReicpe,CHEATSprogress))
                            window.location.reload();
                            }}>{"UPDATE " + CHEATSprogress}</button>
                        
                        </div> 
                      </div>
                          <div className={"CHEATS-flex-row"}>
                                {renderStepItemsStatus(getAllTreeItems(UserProgress)) }
                          </div>
                                              
                          
                    </div>
                    </>
                    : " "}
                  {Loading ? <div>Loading...</div> :" " }
                  {content ? content : "woops"}
                </>
        </>
    )
}

export default Lesson


