import React, { useState, useContext } from "react";
import "./ToggleSwitch.scss";
import { GlobalContext } from "context/GlobalContext";

const ToggleSwitch = ({ }) => {
    
    
    const {VolumeSwitchState, setVolumeSwitchState} = useContext(GlobalContext);
    const soundTap = () => {
        new Audio('/assets/audio/tap.mp3').play();
    }

    const handleToggle = () => {
        setVolumeSwitchState(!VolumeSwitchState);
        if (!VolumeSwitchState) soundTap();
    };

    return (
        <>
            <div onClick={handleToggle} className="toggleSwitch_check-box">
                <input
                    className="toggleSwitch_input"
                    checked={VolumeSwitchState}
                    type="checkbox"
                    onChange={handleToggle} // Add onChange handler
                />
            </div>
        </>
    );
}

export default ToggleSwitch;
