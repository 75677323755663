        
import React from 'react';
import './Xbutton.scss'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';


const Xbutton = ({fixed, onClose}) => {
    
    const history = useHistory();
    const previousPath = history.length > 1 ? history.location.pathname : '/defaultPath'; // Set a default path if there's no history

    return (
    <>
            {onClose ? 
            <div onClick={onClose} className={"top-navbar--circle noselect Xbutton " + (fixed && "fixed")} >
                <img src="/assets/icons/iconX.svg" /> 
            </div>
            :
            <Link
            to={{
                pathname: previousPath,
                // state: { relationship }
                
            }}
            onClick={(event) => {
                event.preventDefault();
                history.goBack();
              }}
            >
            <div className={"top-navbar--circle noselect Xbutton " + (fixed && "fixed")} >
                <img src="/assets/icons/iconX.svg" />
            </div>
            </Link>
            }




        
    </>)
        
        }

export default Xbutton