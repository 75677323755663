import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames';
import gql from 'graphql-tag';
import React, {useState, useRef} from 'react';
import {useMutation, useQuery} from 'react-apollo';
import {useTranslation} from 'react-i18next';
import useJourneyStepMachine from 'utils/hooks/useJourneyStepMachine';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import Step from '../../components/Step/Step';
import {updateMirror} from '../../graphql/mutations';
import {getRelationship} from '../../graphql/queries';
import EmojiToImage from '../../utils/EmojiToImage';
import debounce from 'debounce';
import './Quest.scss';
import {buildJourneyFromStepIds} from "../Journey/JourneyFlowItems";
import {getPlanFromAIChat} from "../../utils/helpers";

const MUTATE_DEBOUNCE_DELAY_IN_MS = 1000;

function Quest(props) {
  const toggleEditDialog = (open, setIsEditDialogOpen) => (event) => {
    setIsEditDialogOpen(open);
  };

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const openEditDialog = React.useCallback(() => {
    setIsEditDialogOpen(true);
  }, [setIsEditDialogOpen]);

  const {t} = useTranslation();
  const questFormRef = useRef();
  const {match, relationship} = props;
  const {params} = match;
  const {id, journeyID, questID: questIDStr} = params;
  const relationshipID = id;
  const questID = Number(questIDStr);
  const {mySideMirrors, name, partnerName, gender, partnerGender} = relationship;
  const intialQuestData = mySideMirrors.find((m) => m.id === journeyID);


  const [state, send] = useJourneyStepMachine(intialQuestData, questID);
  const journeyFlow = buildJourneyFromStepIds(state.context.journey.journeyStepIds)
  const questFlow = journeyFlow[questID];

  const [updateQuestMutation] = useMutation(gql(updateMirror));
  const [updateQuestLoading, setUpdateQuestLoading] = useState(false);
  const debouncedUpdateQuestMutation = debounce(updateQuestMutation, MUTATE_DEBOUNCE_DELAY_IN_MS);

  const mutateQuestData = React.useCallback(
    async (questDataToSave) => {
      setUpdateQuestLoading(true);
      try {
        await debouncedUpdateQuestMutation({variables: {input: questDataToSave}});
      } finally {
        setUpdateQuestLoading(false)
      }
    },
    [updateQuestMutation]
  );

  const getStepItemsFlow = React.useCallback(
    (stepIdx) => {
      return questFlow.steps[stepIdx].stepItems.map((stepItem) => {
        return stepItem;
      });
    },
    [questFlow]
  );

  const {
    selectedStepNumberContext,
    stepsContext,
    CTAtextContext,
    isCTAContext,
    isBackContext,
    isExitContext,
    journey: questData
  } = state.context;


  return (
    <>
      <Dialog open={isEditDialogOpen} onClose={toggleEditDialog(false, setIsEditDialogOpen)}>
        <div className="modal-buttons">
          <Button
            isLink={true}
            to={`/relationship/${relationshipID}/journey/${journeyID}`}
            text={t('mirroring.pause.exit')}
          />
          <p className="micro-text micro-text__white">{t('mirroring.pause.exit-minitext')}</p>
          <Button
            className="close"
            text={t(`general.back`)}
            onClick={() => setIsEditDialogOpen(false)}
          />
        </div>
      </Dialog>
      <div className={'exitOverlay ' + (isExitContext ? '' : 'displayNone')}>
        <div className="overlayBox noselect">
          <div className="questCompletedImg noselect">
            <div className="v-container add-bounce-animation noselect">
              <div className="v-icon">{EmojiToImage("v", false)}</div>
            </div>
            <div className={"img noselect "}>{EmojiToImage(questFlow.icon, false)}</div>
            <div className={"circle noselect"}></div>
          </div>

          <h3 className="exitOverlay-topTitle fade-in_slow-1 noselect">{t("general.questCompleted")}</h3>
          <h2 className="noselect fade-in_slow-2">{t(questFlow.title, {
            name: name,
            partnerName: partnerName
          })}</h2>
          <h4 style={{color: 'white'}}>
            {getPlanFromAIChat(questData.AISummary)}
          </h4>
          {!updateQuestLoading && <Button
            classes="button-primary button3D exitOverlay-button fade-in_slow-3"
            text={t(`general.continue`)}
            isLink={true}
            to={`/relationship/${relationshipID}/journey/${journeyID}`}
          />}
        </div>
      </div>
      <div className="questContent">
        <SideBarPanelNav
          left1={'pauseButton'}
          right={'progressBar'}
          rightAction={openEditDialog}
          selectedStepNumberContext={selectedStepNumberContext}
          stepsArray={stepsContext}
          leftTo={`/relationship/${relationshipID}/journey/${journeyID}`}
        />
        <div className="questForm" ref={questFormRef}>
          {stepsContext.map((stepContext, stepIdx) => {
            return (
              <Step
                key={stepIdx}
                title={t(questFlow.steps[stepIdx].title, {
                  name: name,
                  partnerName: partnerName
                })}
                stepItemsFlow={getStepItemsFlow(stepIdx)}
                questData={questData}
                isSelected={stepIdx === selectedStepNumberContext}
                stepNumber={stepIdx}
                stepContext={stepContext}
                stepFlow={questFlow.steps[stepIdx]}
                name={name}
                partnerName={partnerName}
                gender={gender}
                partnerGender={partnerGender}
                send={send}
                saveQuestData={mutateQuestData}
                questFormRef={questFormRef}
              />
            );
          })}
        </div>
        <div className="questButtons__container noselect">
          <div className="questButtons-gradient"></div>
          <div className="questButtons">

            {isBackContext ? (
              <button
                className={'button button-secondary back'}
                onClick={() => {
                  send({type: 'BACK'});
                }}
              >
                {t('general.back')}
              </button>
            ) : (
              ''
            )}
            <button
              className={classnames('button button3D button-primary cta', {
                buttonDisabled: !isCTAContext
              })}
              onClick={() => {
                const {
                  context: {journey}
                } = send({type: 'CTA'});
                mutateQuestData(journey);
              }}
            >
              {t(`general.${CTAtextContext}`)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function QuestWrapper(props) {
  const {match} = props;
  const {loading, error, data} = useQuery(gql(getRelationship), {
    variables: {id: match.params.id}
  });

  if (loading) {
    return <Loading/>;
  }
  return <Quest {...props} relationship={data.getRelationship}/>;
}

export default QuestWrapper;
