import React, {useEffect, useRef, useState} from "react";
import {omit} from "lodash";
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {talkToBummer} from "../../graphql/mutations";
import {getPlanFromAIChat} from "../../utils/helpers";
import './AIStep.scss';

const AIStep = ({mirrorData, conflictData , Side2_Nice}) => {

  const [talkToBummerMutation, {data, loading = true, error}] = useMutation(gql(talkToBummer));

  const [userAnswer, setUserAnswer] = useState();
  const [currentChat, setCurrentChat] = useState();
  const [shouldRestartChat, setShouldRestartChat] = useState(false);
  const bottomEl = useRef(null);

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({behavior: 'smooth'});
  };

  const [LinesWrapperCount, setLinesWrapperCount] = useState(document.querySelectorAll('.linesWrapper').length);


  useEffect(() => {
    const flattenedJourney = JSON.stringify(omit(mirrorData, ['AISummary', 'progress'])) + ". conflictData = " + conflictData + ". Side2_Nice = " + Side2_Nice;
    // fetch ai summary
    if (loading) return;
    talkToBummerMutation({
      variables: {
        mirrorId: mirrorData.id,
        relationshipObject: flattenedJourney,
        newUserInput: userAnswer,
        shouldRestart: shouldRestartChat
      }
    });
    setUserAnswer(null);
    if (shouldRestartChat) setShouldRestartChat(false);
  }, [userAnswer, shouldRestartChat])

  useEffect(() => {
    if (data) {
      setCurrentChat(data.talkToBummer.AISummary);
    }
  }, [data])

  useEffect(() => {
    console.log("LinesWrapperCount", LinesWrapperCount)
    setLinesWrapperCount(document.querySelectorAll('.linesWrapper').length)
    if (currentChat)
      scrollToBottom()
  }, [currentChat, LinesWrapperCount])

  const handleQuestionClick = (line) => {
    setUserAnswer(line)
  }

  const handleRestartChat = () => {
    setUserAnswer(null);
    setCurrentChat(null);
    setShouldRestartChat(true);
  }

  const formatAISummary = (AISummary) => {
    return AISummary.slice(2).map(chatMessageObject => {
      const lines = chatMessageObject.content.split('\n');
      return (
        <div className={`linesWrapper`}>
            {lines.map((line, index) => {
              let isAdminStuff = false;
              let isMessage1 = false;
              let isMessage2 = false;
              let isMessage3 = false;
              let isRefinedSuggestedSolution = false;
              let isSide2message1 = false;
              let isSide2message2 = false;
              let isStatus = false;
              let isStatusGood = false;
              let isStatusSame = false;
              let isStatusWorse = false;
              let isChange = false;

              if (line.includes("***")) {
                isAdminStuff = true;
                line = line.replace("***", '');
              }
              if (line.includes("Side-1 1st Message:")) {
                isMessage1 = true;
                line = line.replace("Side-1 1st Message:", '');
              }
              if (line.includes("Side-1 2nd Message:")) {
                isMessage2 = true;
                line = line.replace("Side-1 2nd Message:", '');
              }
              if (line.includes("Side-1 suggestion:")) {
                isMessage3 = true;
                line = line.replace("Side-1 suggestion:", '');
              }
              if (line.includes("Side-2 message:")) {
                isSide2message1 = true;
                line = line.replace("Side-2 message:", '');
              }
              if (line.includes("Side-2 2nd message:")) {
                isSide2message2 = true;
                line = line.replace("Side-2 2nd message:", '');
              }
              if (line.includes("CONFLICT-STATUS:")) {
                isStatus = true;
                line = line.replace("CONFLICT-STATUS:", 'STATUS: ');
                if (line.includes("GOOD!")) {
                  isStatusGood = true;
                }else
                if (line.includes("SAME")) {
                  isStatusSame = true;
                }else
                if (line.includes("WORSE")) {
                  isStatusWorse = true;
                }
              }
              if (line.includes("CHANGE:")) {
                isChange = true;
                line = line.replace("CHANGE:", '');
              }

              return (
                <>
                  <p
                    key={index}
                    className={((isAdminStuff) ? " AdminStuff" : "") + ((isMessage1 || isMessage2 || isMessage3 || isRefinedSuggestedSolution) ? " questionAI" : "") + ((isSide2message1 || isSide2message2) ? " answerAI" : "") + ((isStatus) ? " StatusAI" : "") + ((isStatusGood) ? " StatusAI-Good" : "") + ((isStatusSame) ? " StatusAI-Same" : "") + ((isStatusWorse) ? " StatusAI-Worse" : "") + ((isChange) ? " ChangeAI" : "")}  
                    // onClick={isQuestion ? () => handleQuestionClick(line) : undefined}
                    >
                    {line}
                  </p>
                </>
              );
            })}
        </div>
      );
    })
  }

  if (error)
    return <div> Something unexpected happened... please try again later </div>

  return (
    <div className="entireChat">
      
      {/* Let's talk to bummer! */}
      {currentChat && formatAISummary(currentChat)}
      {/* {loading ? <div className="answerAI answerAI-typing">Typing...</div> : ''} */}
      {loading ? (
        <div class="answerAI">
            <div class="typing">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
        </div>
      ) : <div className=" refreshAI-container">
            <button className=" refreshAI" onClick={handleRestartChat}>Refresh</button>
          </div>}
      
      {/* {getPlanFromAIChat(currentChat) && <button onClick={handleRestartChat}>I don't like this plan</button>} */}
      <div ref={bottomEl}></div>
    </div>
  )
}

export default AIStep



// ------

// import React, {useEffect, useRef, useState} from "react";
// import {omit} from "lodash";
// import {useMutation} from "react-apollo";
// import gql from "graphql-tag";
// import {talkToBummer} from "../../graphql/mutations";
// import {getPlanFromAIChat} from "../../utils/helpers";
// import './AIStep.scss';

// const AIStep = ({mirrorData, conflictData, OVERRULES, Side2_Personality}) => {

//   const [talkToBummerMutation, {data, loading = true, error}] = useMutation(gql(talkToBummer));

//   const [userAnswer, setUserAnswer] = useState();
//   const [currentChat, setCurrentChat] = useState();
//   const [shouldRestartChat, setShouldRestartChat] = useState(false);
//   const bottomEl = useRef(null);

//   const scrollToBottom = () => {
//     bottomEl?.current?.scrollIntoView({behavior: 'smooth'});
//   };

//   const [LinesWrapperCount, setLinesWrapperCount] = useState(document.querySelectorAll('.linesWrapper').length);


//   useEffect(() => {
//     const flattenedJourney = JSON.stringify(omit(mirrorData, ['AISummary', 'progress'])) + ". conflictData = " + conflictData + ". Side2_Personality = " + Side2_Personality + ". OVERRULES = " + OVERRULES ;
//     // fetch ai summary
//     if (loading) return;
//     talkToBummerMutation({
//       variables: {
//         mirrorId: mirrorData.id,
//         relationshipObject: flattenedJourney,
//         newUserInput: userAnswer,
//         shouldRestart: shouldRestartChat
//       }
//     });
//     setUserAnswer(null);
//     if (shouldRestartChat) setShouldRestartChat(false);
//   }, [userAnswer, shouldRestartChat])

//   useEffect(() => {
//     if (data) {
//       setCurrentChat(data.talkToBummer.AISummary);
//     }
//   }, [data])

//   useEffect(() => {
//     console.log("LinesWrapperCount", LinesWrapperCount)
//     setLinesWrapperCount(document.querySelectorAll('.linesWrapper').length)
//     if (currentChat)
//       scrollToBottom()
//   }, [currentChat, LinesWrapperCount])

//   const handleQuestionClick = (line) => {
//     setUserAnswer(line)
//   }

//   const handleRestartChat = () => {
//     setUserAnswer(null);
//     setCurrentChat(null);
//     setShouldRestartChat(true);
//   }

//   const formatAISummary = (AISummary) => {
//     return AISummary.slice(2).map(chatMessageObject => {
//       const lines = chatMessageObject.content.split('\n');

//   return (
//   <div className={`linesWrapper`}>
//           {lines.map((line, index) => {
//   // const isQuestion = /^\d+\./.test(line);
//   const isQuestion = /#QUESTION/.test(line);
//   // const isAnswer = /Bummer:/.test(line);
//   const isPlan = /#PLAN/.test(line);
//   const isAnswer = /#BUMMER/.test(line) || /Bummer:/.test(line);
//   const isStartQuestions = /#START-QUESTIONS/.test(line);
//   const isEndQuestions = /#END-QUESTIONS/.test(line);
//   const isAdminInfo = /#ADMIN-INFO/.test(line);
  
  
//   if (isPlan) {
//     line = line.replace(/#PLAN/, ''); 
//   }
//   if (isAnswer) {
//     line = line.replace(/#BUMMER/, ''); 
//   }
//   if (isStartQuestions) {
//     line = line.replace(/#START-QUESTIONS/, ''); 
//   }

//   if (isEndQuestions) {
//     line = line.replace(/#END-QUESTIONS/, ''); 
//   }

//   // Remove numbers from the beginning of the line if it's a question
//   if (isQuestion) {
//     line = line.replace(/#QUESTION/, ''); // Remove numbers followed by a dot
//   } else if (line.trim() === '') {
//     return null; // Skip empty lines after questions
//   }

//   return (
//     <>
//       <p
//         key={index}
//         className={(isQuestion ? "questionAI" : "") + " " + (isAnswer ? "answerAI" : "") + " " + (isPlan ? "planAI" : "") + " " + (isAdminInfo ? "AdminInfoAI" : "") + " " + (isEndQuestions ? "End3Questions" : "")}
//         onClick={isQuestion ? () => handleQuestionClick(line) : undefined}>
//         {line}
//       </p>
//     </>
//     );
//   })}
//         </div>

//       );
//     })
//   }

//   if (error)
//     return <div> Something unexpected happened... please try again later </div>

//   return (
//     <div className="entireChat">
//       {/* Let's talk to bummer! */}
//       {currentChat && formatAISummary(currentChat)}
//       {loading ? <div className="answerAI answerAI-typing">Typing...</div> : ''}
//       {/* {getPlanFromAIChat(currentChat) && <button onClick={handleRestartChat}>I don't like this plan</button>} */}
//       <div className="questionAI questionAI-startOver" onClick={handleRestartChat}>Start over...</div>
//       <div ref={bottomEl}></div>
//     </div>
//   )
// }

// export default AIStep