import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
// import Animal from '../../components/Animal/Animal';
import Instructor from '../../components/Instructor/Instructor';
import './LessonListItem.scss';
function LessonListItem({pathname, size, state, isCurrent, title, description, imgPath, AnimalType, instructorName, badge}){
    const {t} = useTranslation();
    return(
        <>
               {/* (badge ? "LessonListItem__container--list-item__disabled badge_" + badge : 
                 (state=="disabled" ? " LessonListItem__container--list-item__disabled" : " ") + 
               (state=="completed" ? " LessonListItem__container--list-item__completed" : "") 
                 ) */}
        <div className={state=="disabled" && "disabledClick"}>
            <Link
                to={{
                pathname: `theatre/${pathname}`,
                // state: { courseName: pathname }
                }}
              >
            <div className={[
                  "LessonListItem__container--list-item ",
                  ("LessonListItem__container_" + size),
                  (isCurrent && "LessonListItem__container_current "),
                  (state=="completed" && "LessonListItem__container_completed")
                  ].join(" ")}>

                  {/* {badge=="comingSoon" && <div className="Lesson_comingSoonBadge">{t("general.comingSoon")}</div>} */}
                  <div className="LessonListItem__title">{title}</div>           
                  {isCurrent && <div className='pointer'><img  src="/assets/icons/icon_arrow-down.svg"/></div>}
                  <div className={["lessonImg-container", (isCurrent && "backgroundGlow shadowGlow")].join(" ")} >
                    <img className={["lessonImg", (isCurrent && "backgroundGlow shadowGlow")].join(" ")}alt={`catbears language`} src={"/" + imgPath}/>
                    {state=="completed" && <img className="Lesson_Trophy bounceDelayed" alt={`Lesson complete!`} src={"assets/icons/icon_trophy.png"} />}
              </div>
              <Instructor 
                AnimalType ={AnimalType} instructorName ={instructorName}
                intructorBottom = "-74px"
                intructorSize = "180px"
                intructorNameSize = "15px"
                // intructorNameColor = "red"
                intructorNameMargin = "0px"
              />
            </div>
            </Link>
        </div>
    </>
    )
}


export default LessonListItem;

