import React from 'react';
import "./BulletsBlock.scss"
import { useTranslation } from 'react-i18next';

const BulletsBlock = ({bullets, fontSize}) => {

   fontSize && document.documentElement.style.setProperty("--BulletsBlock-fontSize", fontSize );
   const {t} = useTranslation();

  return (
    <>
    {bullets && <ol className="BulletsBlock-container">
        {bullets.map((bullet, index) => {
            return (
                <li key={index} className="BulletsBlock-bullet">{t(bullet)}</li>
            )
        })}
    </ol>}
    </>
  );
};

export default BulletsBlock;