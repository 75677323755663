import React, { useState, useEffect, useRef } from 'react';

const CheatsListener = (triggerFunction) => {
    const [typedString, setTypedString] = useState('');
    const [lastKeyTime, setLastKeyTime] = useState(null);
    const targetString = "bubu";
    const maxInterval = 500; // max interval in milliseconds to consider the typing as "fast"

    useEffect(() => {
        const handleKeyDown = (event) => {
            const key = event.key.toLowerCase();
            const currentTime = new Date().getTime();

            if (targetString.startsWith(typedString + key)) {
                if (typedString.length === 0) {
                    setLastKeyTime(currentTime);
                } else if (currentTime - lastKeyTime > maxInterval) {
                    resetTyping();
                    return;
                }

                setTypedString(prev => prev + key);
                setLastKeyTime(currentTime);

                if (typedString + key === targetString) {
                    if (typeof triggerFunction === 'function') {
                        triggerFunction();
                    }
                    resetTyping();
                }
            } else {
                resetTyping();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [typedString, lastKeyTime, triggerFunction]);

    const resetTyping = () => {
        setTypedString('');
        setLastKeyTime(null);
    };

    return null; // No div is returned
};

export default CheatsListener;
