import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FullScreenStepper.scss';
import FeltLogo from "../../components/FeltLogo/FeltLogo";
import ButtonsFooter from '../../components/ButtonsFooter/ButtonsFooter';
import { isFunction } from "lodash";
import Backgrounds from '../../components/Backgrounds/Backgrounds';

function FullScreenStepper({
  stepsElements,
  onFinish,
  classes,
  stepNumber,
  CurrentLessonIndex,
  CurrentCourseIndex,
  loginFunc,
  addEvent,
  exitStepper,
}) {
  const { t } = useTranslation();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const steps = stepsElements.filter(({ stepDisabled }) => !stepDisabled);
  const maxStepIndex = steps.length - 1;
  const currentStep = steps[currentStepIndex];

  if (!currentStep) return null;
  
  
    document.documentElement.style.setProperty('--stepper_backgroundColor', currentStep.backgroundColor)
    document.documentElement.style.setProperty('--stepper_color', currentStep.color);
    document.documentElement.style.setProperty('--stepper_titleShadowColor', currentStep.titleShadowColor);
    document.documentElement.style.setProperty('--shouldLeftCenter', (currentStep.shouldLeftCenter ? "center" : "space-between"));

  document.documentElement.style.setProperty('--stepper_backgroundColor', currentStep.backgroundColor);
  document.documentElement.style.setProperty('--stepper_color', currentStep.color);
  document.documentElement.style.setProperty('--stepper_titleShadowColor', currentStep.titleShadowColor);
  document.documentElement.style.setProperty('--shouldLeftCenter', (currentStep.shouldLeftCenter ? "center" : "space-between"));

  if (currentStep.isHero) {
    document.documentElement.style.setProperty('--stepper_h1FontSize', "60px");
    document.documentElement.style.setProperty('--stepper_h2FontSize', "25px");
  } else {
    document.documentElement.style.setProperty('--stepper_h1FontSize', "45px");
    document.documentElement.style.setProperty('--stepper_h2FontSize', "20px");
  }

  const onContinue = async () => {
    const stepOnContinue = currentStep.onContinue;
    if (isFunction(stepOnContinue)) await stepOnContinue();
    if (currentStepIndex < maxStepIndex) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      onFinish();
    }
  }


  return (
    <>
      <div className={"FullScreenStepper--meat " + classes}>
        {currentStep.background ? <Backgrounds name={currentStep.background} /> : ""}

        <div className={"FullScreenStepper--container " + classes}>
          <div className="FullScreenStepper--LEFT-RIGHT">
            <div className={"FullScreenStepper--LEFT "}>
              <div className="FullScreenStepper--top">
                {currentStep.topLogo && (
                  <div className="FullScreenStepper--top-logo">
                    <FeltLogo color={currentStep.topLogoWhite ? "white" : "orange"} />
                  </div>
                )}
              </div>
              <div className="FullScreenStepper--middle">
                <div className="MIDDLE-LEFT-content">
                  <div className="FullScreenStepper--middle__content">
                    {currentStep.topContent && <div className="topContent">{currentStep.topContent}</div>}
                    {currentStep.miniTitle && <p className="miniTitle">{currentStep.miniTitle}</p>}
                    {currentStep.title && <h1 className="title">{currentStep.title}</h1>}
                    {currentStep.subtitle && <h2 className="subtitle">{currentStep.subtitle}</h2>}
                    {currentStep.notice && <p className="notice">{currentStep.notice}</p>}
                    {currentStep.paragraph && <p className="paragraph">{currentStep.paragraph}</p>}
                    {currentStep.bottomContent || currentStep.element && (
                      <div className="bottomContent">
                        {isFunction(currentStep.element)
                          ? currentStep.element(onContinue)
                          : currentStep.element}
                        
                      </div>
                    )}
                  </div>
                  <div className="MIDDLE-RIGHT-content-MOBILE-ONLY">
                    {currentStep.rightElementContent}
                  </div>
                </div>
              </div>
              {!currentStep.shouldHideFooter && (
                <div className="FullScreenStepper--bottom">
                  <ButtonsFooter
                    isButtonsOnTop={true}
                    btn1_text={t('general.continue')}
                    btn2_text={currentStep.btn2Text}
                    btn1Design={currentStep.btn1Design || "primary"}
                    btn2Design={currentStep.btn2Design || "primary"}
                    btn1_action={onContinue}
                    btn2_action={""} // TODO
                    btn1_isDisabled={currentStep.isContinueDisabled}
                    footerStyle_BRIGHT={currentStep.footerStyle_BRIGHT}
                    footerStyle_XL={currentStep.footerStyle_XL}
                    footerBackgroundColor={currentStep.footerBackgroundColor}
                    shouldFooterBackgroundTransp={currentStep.shouldFooterBackgroundTransp}
                  />
                </div>
              )}
            </div>
            {currentStep.isRightElement || currentStep.rightElementContent && (
              <div className="FullScreenStepper--RIGHT">
                {currentStep.rightElementContent}
              </div>
            )}
          </div>
        </div>
       
      </div>
    </>
  );
}

export default FullScreenStepper;
