import bummer from "./assets/bummer.png";
import bummer2x from "./assets/bummer@2x.png";
import bummer3x from "./assets/bummer@3x.png";

export default {
  bummer: {
    img: bummer,
    img2x: bummer2x,
    img3x: bummer3x,
    color: "#C9A8F0"
  }
}