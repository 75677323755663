import React from 'react';
import "./PageWrapperBlock.scss"

// 
const PageWrapperBlock = ({ children, backgroundColor, ClassName}) => {

    backgroundColor && document.documentElement.style.setProperty("--PageWrapperBlock-backgroundColor", backgroundColor );

  return (
    <div className={"PageWrapperBlock-container " + (ClassName ? ClassName : "")}>
      {children}
    </div>
  );
};

export default PageWrapperBlock;