import classnames from 'classnames';
// import { t } from 'i18next';
import React from 'react';
import './TextArea.scss';
import { useTranslation } from 'react-i18next';



type TextAreaProps = {
  textAreaClasses: string;
  labelClasses: string;
  label: string;
  rows: number;
  value: string;
  type: string;
  id: string;
  name: string;
  onChangeTextArea: React.EventHandler<any>;
  isMandatory: boolean;
  handleBlur: React.EventHandler<any>;
};

function TextArea(props: TextAreaProps) {
  
  const {
    textAreaClasses,
    labelClasses,
    handleBlur,
    label,
    rows = 3,
    value,
    id,
    name,
    onChangeTextArea,
    isMandatory
  } = props;
  // console.log("isValueEdited from pageloard", isValueEdited)
  // "textarea-empty"
  const { t } = useTranslation();
  const holder = t('general.notMandatory')
  return (
    <div className="textarea-container">
      {label && (
        <label
          className={classnames('form-label noselect', labelClasses, {
            'form-label_notMandatory': !isMandatory
          })}
        >
          {label}
        </label>
      )
      }
      {isMandatory ? "" : <p className='tinyPrint'>{holder}</p>}
      <textarea
        className={classnames('form-textarea', textAreaClasses)}
        rows={rows}
        id={id}
        name={name}
        defaultValue={value}
        onChange={(event) => {
          onChangeTextArea(event.target.value);
        }}
        onBlur={handleBlur}
      ></textarea>
    </div>
  );
}

export default TextArea;
