import React from 'react';
import "./ButtonIconText.scss";
import {Link} from 'react-router-dom'
import {noop} from "lodash";

function ButtonIconText({classes, text, icon, onClick, to}) {

  const ButtonContent = ({onClick = noop}) => (
    <div onClick={onClick} className={"ButtonIconText noselect " + classes}>
      <div className={"ButtonIconText__icon noselect " + classes}>
        <img src={icon} alt="img"/></div>
      <div className={"ButtonIconText__text noselect "}>{text}</div>
    </div>
  )

  if (to)
    return (
      <Link to={to} onClick={onClick}>
        <ButtonContent/>
      </Link>
    )
  else {
    return <ButtonContent onClick={onClick}/>


  }
}

export default ButtonIconText;