import React, { useEffect } from 'react';
import ButtonCircle from '../../components/ButtonCircle/ButtonCircle';
import ButtonIconText from '../../components/ButtonIconText/ButtonIconText';
import ProgressDots from '../../components/ProgressDots/ProgressDots';
import { useTranslation } from 'react-i18next';
import './SideBarPanelNav.scss';

function SideBarPanelNav({
  left1,
  left2,
  summaryURL,
  copytoClipboardFunc,
  center,
  centerTitle,
  centerSubTitle,
  right,
  leftTo,
  rightTo,
  currentStepState,
  progressDotsColor,
  rightAction,
  selectedStepNumberContext,
  stepsArray,
  classes,
  fixed
}) {

  const { t } = useTranslation();

  let leftContent;
  let centerContent;
  let rightContent;

  const summaryButton = (
  <ButtonIconText
    to={summaryURL}
    text={t("summary.summary")}
    icon={'/assets/icons/icon_30_summary.png'}
    classes={'cta'}
  />
  );

  const settingsButton = (
    <ButtonCircle
        classes={'ButtonCircle-settings ButtonCircle-50px '}
        onClick={rightAction}
        // to={leftTo ? leftTo : ""}
      />
  );

  const copytoClipboard = (
    <ButtonIconText
      onClick={copytoClipboardFunc}
      text={t('general.copyToClipboard')}
      icon={'/assets/icons/icon_30_copytoclipboard.png'}
    />
  );

  useEffect(() => {}, [currentStepState]);

  if (left1 === 'pauseButton') {
    left1 = (
      <ButtonCircle
        classes={'ButtonCircle-pause ButtonCircle-50px '}
        onClick={rightAction}
        // to={leftTo ? leftTo : ""}
      />
    );
  } else if (left1 === 'backButton') {
    left1 = (
      <ButtonCircle
        classes={'ButtonCircle-back ButtonCircle-50px '}
        // onClick={()=> {alert("pause!")}}
        to={leftTo ? leftTo : '/'}
      />
    );
  } else {
    // left1 = left1;
  }

  if (left2 === 'summaryButton') {
    left2 = summaryButton
  }

  leftContent = (
    <>
    <div>
    {left1 ? left1 : ""}
    </div>
    <div>
    {left2 ? left2 : ""}
    </div>
    
    </>
    )
  if (right === 'settingsButton') {
    rightContent = settingsButton;

  } else if (right === 'summaryButton') {
    rightContent = summaryButton;
    
  } else if (right === 'lobby') {
    rightContent = 
              <div onClick={rightAction} className={"cursorPointer noselect "} >
                <img src="/assets/icons/icon-print.svg" />
                </div>
    
    
    
    // <ButtonCircle
    //       classes={'ButtonCircle-print ButtonCircle-50px '}
    //       onClick={rightAction}
    //       // to={leftTo ? leftTo : ""}
    //     />
        
  } else if (right === 'copytoClipboard') {
    rightContent = copytoClipboard;

  } else if (right === 'progressBar') {
    rightContent = <ProgressDots selectedDot={selectedStepNumberContext} dotsArray={stepsArray} />;
  } else {
    rightContent = right;
  }

  if (center === 'twoLinesTopTitle') {
    centerContent = (
      <>
        <div className="SideBarPanelNav--center__title">{centerTitle}</div>
        <div className="SideBarPanelNav--center__subTitle">{centerSubTitle}</div>
      </>
    );
  } else if (center === 'twoLinesTopSubTitle') {
    centerContent = (
      <>
        <div className="SideBarPanelNav--center__subTitle">{centerSubTitle}</div>
        <div className="SideBarPanelNav--center__title">{centerTitle}</div>
      </>
    );
  } else if (center === 'oneLine') {
    centerContent = (
      <div className="SideBarPanelNav--center__title SideBarPanelNav--center__title-top-padding">
        {centerTitle}
      </div>
    );
  } else {
    centerContent = center;
  }

  return (
    <>
      <div className={"SideBarPanelNav--container noselect " + (classes ? classes : "") + (fixed ? " SideBarPanelNav--fixed" : "")}>
        <div className="SideBarPanelNav--left">{leftContent}</div>
        {right === 'progressBar' ? (
          ''
        ) : (
          <div className="SideBarPanelNav--center">{centerContent}</div>
        )}
        <div className={'SideBarPanelNav--right ' + (right === 'progressBar' ? 'flex-2' : '')}>
          {rightContent}
        </div>
      </div>
    </>
  );
}

export default React.memo(SideBarPanelNav);
