import React from 'react';
import './ProfileImgName.scss';

function ProfileImgName({classes, size, img, name}){
   return (
      <div className={"profileImgName-container " + classes}>
         <img src={"guest-img.png"} className={"profileImgName-img " } />
         <h2 className="color-white">{name}</h2>
      </div>
      )
}

export default ProfileImgName;