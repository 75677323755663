export const getCardSides = ({switchSides, gender, partnerGender, name, partnerName}) => {
  return {
    sourceGender: switchSides ? partnerGender : gender,
    targetName: switchSides ? partnerName : name,
    sourceName: switchSides ? name : partnerName
  }
}

export const getPlanFromAIChat = (chat) => {
  if (!chat || !chat.length) return null;
  const lastMessageContent = chat[chat.length - 1].content;
  const planIndex = lastMessageContent.search('Plan:')
  if (planIndex === -1) return null;
  return lastMessageContent.slice(planIndex, lastMessageContent.length);
}