import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import * as ANIMALS from '../../components/Animal/AnimalAssets';
import Backgrounds from '../../components/Backgrounds/Backgrounds';
import Button from '../../components/Button/Button';
import CardEditor from '../../components/CardEditor/CardEditor';
import Loading from '../../components/Loading/Loading';
import Stage from '../../components/Stage/Stage';
import { mutate } from '../../graphql/helpers';
import { deleteMirror } from '../../graphql/mutations';
import { getRelationship } from '../../graphql/queries';
import './InGameSideBarView.scss';

document.addEventListener(
  'touchmove',
  function (e) {
    e.preventDefault();
  },
  false
);

const toggleDrawer = (open, setKeyboardState) => (event) => {
  setKeyboardState({ isOpen: open });
};
const toggleDialog = (open, setCardEditorState) => (event) => {
  setCardEditorState({ isOpen: open });
};
const toggleEditDialog = (open, setIsEditDialogOpen) => (event) => {
  setIsEditDialogOpen({ open });
};

function setCssVar(mySideColor, partnerColor) {
  document.documentElement.style.setProperty('--mySideColor', mySideColor);
  document.documentElement.style.setProperty('--partnerColor', partnerColor);
}

// Entry Point ===>
function InGameSideBarView(props) {
  const { history, user, match, journeyItem, children } = props;
  const id = match.params.id;

  const { loading, error, data } = useQuery(gql(getRelationship), {
    variables: { id: match.params.id }
  });

  const [relationship, setRelationship] = useState({ animalId: `dino`, partnerAnimalId: `dino` });

  useEffect(() => {
    if (!loading) {
      if (error) {
        console.log(error);
        return;
      }
      // console.log('data is', data, match.params.mirrorId);
      setRelationship(data.getRelationship);
      if (match.params.mirrorId === 'new') {
        console.log('new mirror');
        return;
      }
    }
  }, [loading]);

  const { t } = useTranslation();
  const [side, setSide] = useState(`mySide`);
  const [stateIsDeleted, setStateIsDeleted] = useState(false);
  const cardGalleryRef = useRef();
  const { name, partnerName, gender, partnerGender, partnerAnimalId, relation } = relationship;

  const [stateSaving, setStateSaving] = useState(false);
  const [stateIsChangesToForm, setStateIsChangesToForm] = useState(false);
  const [stateIsSaveNotification, setStateIsSaveNotification] = useState(false);

  const handleSave = async (saveAndClose, isDeleted) => {
    setStateIsDeleted(isDeleted);

    if (!isDeleted) {
      console.log('hi!');
    }

    setStateSaving(true);
    // checkChanges()

    console.log('current relationship', relationship);

    if (isDeleted && match.params.mirrorId !== 'new') {
      await mutate(deleteMirror, match.params.mirrorId)
        .then(({ data }) => {
          console.log('deleteMirror', data);

          postSave(isDeleted, saveAndClose);
        })
        .catch((err) => {
          console.log(err);
          console.log('1) match.params.mirrorId', match.params.mirrorId);
        });
    }
  };

  const postSave = (isDeleted, saveAndClose) => {
    if (match.params.id === 'new') {
    }
    if (!isDeleted) {
      setStateSaving(false);
      setStateIsChangesToForm(false);
      setStateIsSaveNotification(true);
    }
    if (saveAndClose) {
      history.push({
        pathname: `/relationship/${id}`,
        state: { relationship: { ...relationship } }
      });
    }
  };

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [cardEditorState, setCardEditorState] = useState({ isOpen: false });
  // if (!history.location.state) return <Redirect to={`/`} />;

  let sideData, animalId, setSideState;
  if (side === 'mySide') {
    setSideState = (mySideMirrorData) => {};
    animalId = relationship.animalId;
  }

  const handleBlur = (e) => {
    e.preventDefault();
  };

  if (loading) return <Loading />;

  return (
    <>
      <Dialog
        // title={t('mirroring.edit-relationship')}
        open={isEditDialogOpen}
        onClose={toggleEditDialog(false, setIsEditDialogOpen)}
      >
        <div className="modal-buttons">
          <div className="modal-buttons__delete">
            <Button
              classes="relationship-page__settings-delete-button "
              text={t(`mirroring.edit.delete`)}
              onClick={() => {
                // delete here
                handleSave(true, true);
              }}
            />
          </div>
          <p className="micro-text micro-text__white">{t(`mirroring.edit.delete-warning`)}</p>
          <Button
            className="close"
            text={t(`general.close`)}
            onClick={() => setIsEditDialogOpen(false)}
            // onClick={toggleDialog(false, setIsEditDialogOpen)}
          />
        </div>
      </Dialog>
      <div className="MINI-STEP">
        <div className="MINI-STEP__PANEL">
          <div className="MINI-STEP__PANEL--CONTENT " ref={cardGalleryRef}>
            {children}
          </div>
        </div>

        <div className="MINI-STEP__STAGE stageAndBackground ">
          <Backgrounds
            // name = {'Hills'} // Hills,
            name={'Room'} // Hills,
            classes={"ingame-background"}
          />

          <Stage
            name={name}
            partnerName={partnerName}
            animalId={animalId}
            partnerAnimalId={partnerAnimalId}
            classes=""
            showSides={true}
            mySide_distance={'5%'}
            partnerSide_distance={'5%'}
            background={'hills'}
            // isBummer={true}
          />
          <div className="stageBackground-container stageBackground-container-3d ">
            <div className="stageBackground stageBackground-3d "></div>
          </div>
          {setCssVar(ANIMALS.default[animalId].color, ANIMALS.default[partnerAnimalId].color)}
        </div>
      </div>
      <Dialog open={cardEditorState.isOpen} onClose={toggleDialog(false, setCardEditorState)}>
        {cardEditorState.isOpen && <CardEditor {...cardEditorState} />}
      </Dialog>
    </>
  );
}

export default InGameSideBarView;
