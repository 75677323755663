import isEmpty from './isEmpty';
import {buildJourneyFromStepIds} from "../pages/Journey/JourneyFlowItems";

function getStepsStatus(questData, questID) {
  const journeyFlow = buildJourneyFromStepIds(questData.journeyStepIds);
  const questFlow = journeyFlow[questID];
  const questsFieldsAreCompleted = questData.progress.flowState[questID];
  return questFlow.steps.map((stepFlow, index) => {
    const stepItemIsMandatoryFieldsFilled = stepFlow.stepItems.map((stepItem) => {
      return stepItem.mandatory ? !isEmpty(questData.journeyProps[stepItem.state]) : true;
    });
    const isCompleted = questsFieldsAreCompleted[index].isCompleted;
    const areAllMandatoryFieldsCompleted = !stepItemIsMandatoryFieldsFilled.includes(false);
    return {isCompleted, isMandatory: areAllMandatoryFieldsCompleted};
  });
}

export default getStepsStatus;
